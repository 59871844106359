.progress-bar-container {
    width: 100%;
    background-color:  #9db2d9;


  }
  
  .progress-bar {
    height: 20px;
    position: relative;
 
  }


  

  .skills .progress-bar {
    width: 1px;
    height: 20px;
    color: #4668a2;
display: flex;
justify-content: center;
    transition: 0.9s;
    background-color: #4668a2;
  }
  