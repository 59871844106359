/**
* Template Name: BizLand
* Updated: May 30 2023 with Bootstrap v5.3.0
* Template URL: https://bootstrapmade.com/bizland-bootstrap-business-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
.rec-arrow-left {
  display: none !important;
}

.rec-arrow-right {
  display: none !important;
}

@font-face {

  font-family: "SF Pro Display";
  src: url(./fonts/sf-pro-display-cufonfonts/SFPRODISPLAYREGULAR.OTF) format('opentype');
}


body {
  /* color: #444444; */
  font-family: "SF Pro Display" !important;
  font-size: 17px;
  line-height: 25px !important;
  word-spacing: 3px;

}

a {
  color: #294b86;
  text-decoration: none;
}

a:hover {
  color: #3b8af2;
  text-decoration: none;
}

/* login */
#signUpForm {
  max-width: 750px;
  background-color: #ffffff;
  margin: 60px auto;
  padding: 40px;
  box-shadow: 0px 20px 25px rgb(0 0 0 / 9%);
  border-radius: 12px;
  position: relative;
}

#signUpForm .form-header {
  gap: 5px;
  text-align: center;
  font-size: .9em;
}

#signUpForm input {
  padding: 15px 20px;
  width: 100%;
  font-size: 1em;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
}

#signUpForm input:focus {
  border: 1px solid #009688;
  outline: 0;
}

#signUpForm input.invalid {
  border: 1px solid #ffaba5;
}

#signUpForm .form-footer {
  overflow: auto;
  gap: 20px;
}

#signUpForm .form-footer button {
  background-color: #4285f4;
  border: 1px solid #4285f4 !important;
  color: #ffffff;
  border: none;
  padding: 13px 30px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 3px;
  flex: 1;
}

#signUpForm .form-footer button a {
  color: black;
  font-size: 1em;
  font-family: "SF Pro Display"
}

#signUpForm .form-footer button:hover {
  opacity: 0.8;
}

#signUpForm .form-footer #prevBtn {
  background-color: #fff;
  color: #009688;
}


/* --------------------------------------------------------- */
#signUpForm .whole_password {
  position: relative;
  margin-top: 10px;
  /* margin-bottom: 3em; */
}

#signUpForm .whole_password input+span {
  position: absolute;
  left: 10px;
  top: 15px;
  color: grey;
  font-size: 0.9em;
  cursor: text;
  transition: 0.3s ease;
}

#signUpForm .whole_password input:placeholder-shown+span {
  top: 15px;
  font-size: 15px;
}

#signUpForm .whole_password input:focus+span,
#signUpForm .whole_password input:valid+span {
  top: -3px;
  font-size: 13px;
  font-weight: 600;
}

#signUpForm .whole_password input:valid+span {
  color: green;
}

/* ---------------------------------------------------------------------------------------*/



.registration-divider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.divider-line {
  display: block;
  width: 100%;
  height: 1px;
  background: hsl(0, 0%, 60%);
}

.divider-text {
  display: block;
  margin: 0 2.5rem;
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.75rem;
  letter-spacing: .04063rem;
  color: black;
}

.auth {
  background-color: #4285f4;
  color: white;
  text-align: center;
  padding: 1px;
}

.progress2 {
  width: 234%;
  margin-top: -102px;
  margin-left: -298px;
}

.auth a {
  text-decoration: none;
  color: white;
}

.auth i {
  padding-right: 10px;
  color: white;
}


#heading {
  margin-top: -15px !important;
}

#signUpForm .form-footer a {
  text-decoration: none;
}

#abouts p {
  font-size: 16px;
  line-height: 27px;
  word-spacing: 10px;
}

#abouts h3 span {
  color: #294b86;
}

#abouts {
  background-color: #f2f2f2;
}


/*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #294b86;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #3284f1;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

@media screen and (min-width: 990px) {
  .navbar-btn {
    display: none;
  }

}

@media screen and (min-width: 990px) {
  .navbar-items {
    display: none;

  }


}

@media screen and (min-width: 990px) {
  .buttonmob {
    display: none;

  }


}

.navbar-items ul li {

  list-style: none;
  margin-right: 10px;

  width: auto;


}

.navbar-items a {
  text-decoration: none;
  color: black;
}


.mobilebar {
  width: fit-content;
}

@media screen and (max-width: 500px) {
  .applicationlogo img {
    width: 65% !important;
  }

}


@media screen and (max-width: 360px) {
  .popup {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 50vh !important;

    width: 100% !important;
  }

  .container p {

    text-align: justify;

    padding-left: 17px;
    padding-right: 17px;

  }


  .popup {

    animation: example 0.3s linear 0s !important;
    animation-fill-mode: forwards !important;
    transform: translateY(102px) !important;

    position: absolute !important;
    background-color: #343a40 !important;
    top: -712px !important;
    overflow: scroll !important;
    z-index: 9999 !important;
    width: 100% !important;

    font-size: 17px;
    text-decoration: none;
  }


  .Forgot {
    text-align: center;
  }

  /* .container ul {
      
      text-align:center;
      
      padding-left: 17px;
      padding-right: 17px;
  
      } */
  h2 {

    padding-left: 17px;
    text-align: center;

  }

  button {
    justify-content: center;
  }

  #header .logo img {

    height: 40px;
  }

  #topbar {
    display: none !important;
  }

  .container h1 {
    font-size: 1.5rem !important;

  }

  #header {

    height: 66px !important;
  }


  #job_android {
    background-image: url(./images/job_description/data-scientist.jpg);

    /* height: 75vh;  */
    object-fit: cover;
    background-size: cover;
    color: white;
    background-attachment: fixed;
    opacity: 0.9;
  }

}



p {

  padding-left: 17px;
  padding-right: 17px;
}

h2 {
  /* padding-left: 17px; */
  padding-right: 17px;

}

/*--------------------------------------------------------------
  # Top Bar
  --------------------------------------------------------------*/
#topbar {
  background: #294b86;
  height: 40px;
  font-size: 14px;
  transition: all 0.5s;
  color: #fff;
  padding: 0;
}

#topbar .contact-info i {
  font-style: normal;
  color: #fff;
}

#topbar .contact-info i a,
#topbar .contact-info i span {
  padding-left: 5px;
  color: #fff;
}

#topbar .contact-info i a {
  line-height: 0;
  transition: 0.3s;
  transition: 0.3s;
}

#topbar .contact-info i a:hover {
  color: #fff;
  text-decoration: underline;
}

#topbar .social-links a {
  color: rgba(255, 255, 255, 0.7);
  line-height: 0;
  transition: 0.3s;
  margin-left: 20px;
}

#topbar .social-links a:hover {
  color: white;
}


/*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
#header {
  background: #fff;
  transition: all 0.5s;
  z-index: 997;
  height: 86px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#header.fixed-top {
  height: 70px;
}

#header .logo {
  font-size: 30px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0.8px;

}

#header .logo a {
  color: #222222;
}

#header .logo a span {
  color: #294b86;
}

#header .logo img {
  position: relative;
  max-height: 60px;
}

.scrolled-offset {
  margin-top: 70px;
}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/**
  * Desktop Navigation 
  */
.navbar {
  padding-right: 50px;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar>ul>li {
  white-space: nowrap;
  padding: 10px 0 10px 28px;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3px;
  font-size: 15px;
  font-weight: 600;
  color: #222222;
  white-space: nowrap;
  transition: 0.3s;
  position: relative;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar>ul>li>a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -6px;
  left: 0;
  background-color: #294b86;
  visibility: hidden;
  width: 0px;
  transition: all 0.3s ease-in-out 0s;
}

.navbar a:hover:before,
.navbar li:hover>a:before,
.navbar .active:before {
  visibility: visible;
  width: 100%;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #294b86;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 28px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-weight: 400;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #294b86;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
  * Mobile Navigation 
  */
.mobile-nav-toggle {
  color: #222222;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(9, 9, 9, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}


.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #222222;
}

.navbar-mobile>ul>li {
  padding: 0;
}

.navbar-mobile a:hover:before,
.navbar-mobile li:hover>a:before,
.navbar-mobile .active:before {
  visibility: hidden;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #294b86;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #294b86;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}




.container h1 {
  font-size: 4.5rem;
  color: black;
}


.container h1 span {
  color: #294b86;
}

/*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
#hero {

  width: 100%;
  height: 75vh;
  background-image: url(../src/images/home/index.jpg);
  background-size: cover;
  opacity: 5;
  position: relative;
}

#hero:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero .container {
  position: relative;
}

#hero h1 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #222222;

}

#hero h1 span {
  color: #294b86;
}

#hero h2 {
  color: #555555;
  margin: 5px 0 30px 0;
  font-size: 24px;
  font-weight: 400;
}

#hero .btn-get-started {
  font-family: "SF Pro Display";
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #294b86;
}

#hero .btn-get-started:hover {
  background: #247cf0;
}

#hero .btn-watch-video {
  font-size: 16px;
  transition: 0.5s;
  margin-left: 25px;
  color: #222222;
  font-weight: 600;
  display: flex;
  align-items: center;
}

#hero .btn-watch-video i {
  color: #294b86;
  font-size: 32px;
  transition: 0.3s;
  line-height: 0;
  margin-right: 8px;
}

#hero .btn-watch-video:hover {
  color: #294b86;
}

#hero .btn-watch-video:hover i {
  color: #3b8af2;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #hero {
    height: 49vh;
  }

  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  #hero .btn-get-started,
  #hero .btn-watch-video {
    font-size: 13px;
  }
}

@media (max-height: 500px) {
  #hero {
    height: 120vh;
  }

}




/*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
section {
  padding: 0px 0;
  overflow: hidden;
}

section .topics {
  padding: px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f6f9fe;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 8px 20px;
  margin: 0;
  background: #e7f1fd;
  color: #294b86;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 50px;
}

.section-title h3 {
  margin: 15px 0 0 0;
  font-size: 32px;
  font-weight: 700;
}

.section-title h3 span {
  color: #294b86;
}

.section-title p {
  margin: 15px auto 0 auto;
  font-weight: 600;
}

@media (min-width: 1024px) {
  .section-title p {
    width: 50%;
  }
}

/*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
.breadcrumbs {
  padding: 20px 0;
  background-color: #f1f6fe;
  min-height: 40px;
}

.breadcrumbs h2 {
  font-size: 24px;
  font-weight: 300;
  margin: 0;
}

@media (max-width: 992px) {
  .breadcrumbs h2 {
    margin: 0 0 10px 0;
  }
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #6c757d;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
  # Featured Services
  --------------------------------------------------------------*/
.featured-services {
  background-color: #f2f2f2;

}

.featured-services .icon-box {
  padding: 30px;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
}

.featured-services .icon-box::before {
  content: "";
  position: absolute;
  background: #cbe0fb;
  right: 0;
  left: 0;
  bottom: 0;
  top: 100%;
  transition: all 0.3s;
  z-index: -1;
}

.featured-services .icon-box:hover::before {
  background: #294b86;
  top: 0;
  border-radius: 0px;
}

.featured-services .icon {
  margin-bottom: 15px;
}

.featured-services .icon i {
  font-size: 48px;
  line-height: 1;
  color: #294b86;
  transition: all 0.3s ease-in-out;
}

.featured-services .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.featured-services .title a {
  color: #111;
}

.featured-services .description {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
}

.featured-services .icon-box:hover .title a,
.featured-services .icon-box:hover .description {
  color: #fff;
}

.featured-services .icon-box:hover .icon i {
  color: #fff;
}

/*--------------------------------------------------------------
  # About
  --------------------------------------------------------------*/
.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 35px;
}

.about .content ul li:first-child {
  margin-top: 35px;
}

.about .content ul i {
  background: #fff;
  box-shadow: 0px 6px 15px rgba(16, 110, 234, 0.12);
  font-size: 24px;
  padding: 20px;
  line-height: 1;
  margin-right: 23px;
  color: #294b86;
  border-radius: 50px;
}

.about .content ul h5 {
  font-size: 14px;
  color: #555555;
}

.about .content ul p {
  font-size: 17px;
}

.about .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
  # Skills
  --------------------------------------------------------------*/
.skills .progress {
  height: 60px;
  display: block;
  background: none;
  border-radius: 0;
}

.skills .progress .skill {
  padding: 0;
  margin: 0 0 6px 0;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #222222;
}

.skills .progress .skill .val {
  float: right;
  font-style: normal;
}

.skills .progress-bar-wrap {
  background: #e2eefd;
  height: 10px;
}

.skills .progress-bar {
  width: 1px;
  height: 10px;
  transition: 0.9s;
  background-color: #294b86;
}

/*--------------------------------------------------------------
  # Counts
  --------------------------------------------------------------*/
.counts {
  padding: 70px 0 60px;
}

.counts .count-box {
  padding: 30px 30px 25px 30px;
  width: 100%;
  position: relative;
  text-align: center;
  background: #f1f6fe;
}

.counts .count-box i {
  position: absolute;
  top: -28px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  background: #294b86;
  color: #fff;
  width: 56px;
  height: 56px;
  line-height: 0;
  border-radius: 50px;
  border: 5px solid #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.counts .count-box span {
  font-size: 36px;
  display: block;
  font-weight: 600;
  color: #062b5b;
}

.counts .count-box p {
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

/*--------------------------------------------------------------
  # Clients
  --------------------------------------------------------------*/
.clients {
  padding: 15px 0;
  text-align: center;
}

.clients img {
  max-width: 51%;
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 15px 0;
}

.clients img:hover {
  transform: scale(1.15);
}

@media (max-width: 768px) {
  .clients img {
    max-width: 40%;
  }
}

/*--------------------------------------------------------------
  # Services
  --------------------------------------------------------------*/
.services {
  text-align: center;
  border: 1px solid #e2eefd;
  padding: 30px 15px;
  transition: all ease-in-out 0.3s;
  background: #fff;
}

.services .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #f1f6fe;
  border-radius: 4px;
  border: 1px solid #deebfd;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: ease-in-out 0.3s;
}

.services .icon-box .icon i {
  color: #3b8af2;
  font-size: 28px;
  transition: ease-in-out 0.3s;
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.services .icon-box h4 a {
  color: #222222;
  transition: ease-in-out 0.3s;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .icon-box:hover {
  border-color: #fff;
  box-shadow: 0px 0 25px 0 rgba(16, 110, 234, 0.1);
}

.services .icon-box:hover h4 a,
.services .icon-box:hover .icon i {
  color: #294b86;
}

.services .icon-box:hover .icon {
  border-color: #294b86;
}

/*--------------------------------------------------------------
  # Testimonials
  --------------------------------------------------------------*/
.testimonials {
  padding: 80px 0;
  background: url("./images/home/testimonials.jpg") no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
}

.d-flex a {
  text-decoration: none;
}

.testimonials::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}

.testimonials .section-header {
  margin-bottom: 40px;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  text-align: center;
  color: #fff;
}

.testimonials .testimonial-item .testimonial-img {
  width: 100px;
  border-radius: 50%;
  border: 6px solid rgba(255, 255, 255, 0.15);
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #fff;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #ddd;
  margin: 0 0 15px 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: rgba(255, 255, 255, 0.4);
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
  color: #eee;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #294b86;
}

@media (min-width: 992px) {
  .testimonials .testimonial-item p {
    width: 80%;
  }
}

/*--------------------------------------------------------------
  # Portfolio
  --------------------------------------------------------------*/
.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 15px auto;
  list-style: none;
  text-align: center;
  border-radius: 50px;
  padding: 2px 15px;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 10px 15px 8px 15px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: #294b86;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio .portfolio-item .portfolio-info {
  opacity: 0;
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px;
}

.portfolio .portfolio-item .portfolio-info h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  color: #222222;
}

.portfolio .portfolio-item .portfolio-info p {
  color: #555555;
  font-size: 14px;
  margin-bottom: 0;
}

.portfolio .portfolio-item .portfolio-info .preview-link,
.portfolio .portfolio-item .portfolio-info .details-link {
  position: absolute;
  right: 40px;
  font-size: 24px;
  top: calc(50% - 18px);
  color: #3c3c3c;
}

.portfolio .portfolio-item .portfolio-info .preview-link:hover,
.portfolio .portfolio-item .portfolio-info .details-link:hover {
  color: #294b86;
}

.portfolio .portfolio-item .portfolio-info .details-link {
  right: 10px;
}

.portfolio .portfolio-item .portfolio-links {
  opacity: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 3;
  position: absolute;
  transition: all ease-in-out 0.3s;
}

.portfolio .portfolio-item .portfolio-links a {
  color: #fff;
  margin: 0 2px;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-item .portfolio-links a:hover {
  color: #6ba7f5;
}

.portfolio .portfolio-item:hover .portfolio-info {
  opacity: 1;
  bottom: 20px;
}

/*--------------------------------------------------------------
  # Portfolio Details
  --------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #294b86;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #294b86;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(34, 34, 34, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
  # Team
  --------------------------------------------------------------*/
.team {
  padding: 60px 0;
}

.team .member {
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(16, 110, 234, 0.15);
}

.team .member .member-img {
  position: relative;
  overflow: hidden;
}

.team .member .social {
  position: absolute;
  left: 0;
  bottom: 30px;
  right: 0;
  opacity: 0;
  transition: ease-in-out 0.3s;
  text-align: center;
}

.team .member .social a {
  transition: color 0.3s;
  color: #222222;
  margin: 0 3px;
  padding-top: 7px;
  border-radius: 4px;
  width: 36px;
  height: 36px;
  background: rgba(16, 110, 234, 0.8);
  display: inline-block;
  transition: ease-in-out 0.3s;
  color: #fff;
}

.team .member .social a:hover {
  background: #3b8af2;
}

.team .member .social i {
  font-size: 18px;
}

.team .member .member-info {
  padding: 25px 15px;
}

.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
  color: #222222;
}

.team .member .member-info span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #aaaaaa;
}

.team .member .member-info p {
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  color: #777777;
}

.team .member:hover .social {
  opacity: 1;
  bottom: 15px;
}

/*--------------------------------------------------------------
  # Pricing
  --------------------------------------------------------------*/
.pricing .box {
  padding: 20px;
  background: #fff;
  text-align: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.pricing .box h3 {
  font-weight: 400;
  margin: -20px -20px 20px -20px;
  padding: 20px 15px;
  font-size: 16px;
  font-weight: 600;
  color: #777777;
  background: #f8f8f8;
}

.pricing .box h4 {
  font-size: 36px;
  color: #294b86;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}

.pricing .box h4 sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}

.pricing .box h4 span {
  color: #bababa;
  font-size: 16px;
  font-weight: 300;
}

.pricing .box ul {
  padding: 0;
  list-style: none;
  color: #444444;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}

.pricing .box ul li {
  padding-bottom: 16px;
}

.pricing .box ul i {
  color: #294b86;
  font-size: 18px;
  padding-right: 4px;
}

.pricing .box ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.pricing .btn-wrap {
  margin: 20px -20px -20px -20px;
  padding: 20px 15px;
  background: #f8f8f8;
  text-align: center;
}

.pricing .btn-buy {
  background: #294b86;
  display: inline-block;
  padding: 8px 35px 10px 35px;
  border-radius: 4px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  transition: 0.3s;
}

.pricing .btn-buy:hover {
  background: #3b8af2;
}

.pricing .featured h3 {
  color: #fff;
  background: #294b86;
}

.pricing .advanced {
  width: 200px;
  position: absolute;
  top: 18px;
  right: -68px;
  transform: rotate(45deg);
  z-index: 1;
  font-size: 14px;
  padding: 1px 0 3px 0;
  background: #294b86;
  color: #fff;
}

/*--------------------------------------------------------------
  # Frequently Asked Questions
  --------------------------------------------------------------*/
.faq {
  padding: 60px 0;
}

.faq .faq-list {
  padding: 0;
  list-style: none;
}

.faq .faq-list li {
  border-bottom: 1px solid #d4e5fc;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.faq .faq-list .question {
  display: block;
  position: relative;
  font-family: #294b86;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  padding-left: 25px;
  cursor: pointer;
  color: #ffffff;
  transition: 0.3s;
}


#reactop5 h1 span {
  color: #294b86;
}

.faq .faq-list {
  padding: 0;
  list-style: none;
}

.faq .faq-list li {
  border-bottom: 1px solid #d4e5fc;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.faq .faq-list .question {
  display: block;
  position: relative;
  font-family: #294b86;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  padding-left: 25px;
  cursor: pointer;
  color: #ffffff;
  transition: 0.3s;
}

#reactop4 {
  background-image: url(./images/For_devs/bg.jpg);
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;
}

#reactop5 h1 span {
  color: #294b86;
}

#reactop3 h1 span {
  color: #294b86;
}

.faq .faq-list i {
  font-size: 16px;
  position: absolute;
  left: 0;
  top: -2px;
}

.faq .faq-list p {
  margin-bottom: 0;
  padding: 10px 0 0 25px;
}

.faq .faq-list .icon-show {
  display: none;
}

.faq .faq-list .collapsed {
  color: black;
}

.faq .faq-list .collapsed:hover {
  color: #294b86;
}

.faq .faq-list .collapsed .icon-show {
  display: inline-block;
  transition: 0.6s;
}

.faq .faq-list .collapsed .icon-close {
  display: none;
  transition: 0.6s;
}

.faq {
  padding: 60px 0;
}

.faq .faq-list {
  padding: 0;
  list-style: none;
}

.faq .faq-list li {
  border-bottom: 1px solid #d4e5fc;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.faq .faq-list .question {
  display: block;
  position: relative;
  font-family: #294b86;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  padding-left: 25px;
  cursor: pointer;
  color: #0d58ba;
  transition: 0.3s;
}

.faq .faq-list i {
  font-size: 10px;
  position: absolute;
  left: 0;
  top: -2px;
}

.faq .faq-list p {
  margin-bottom: 0;
  padding: 0px 0 0 0;

}

.faq .faq-list .icon-show {
  display: none;
}

.faq .faq-list .collapsed {
  color: black;
}

.faq .faq-list .collapsed:hover {
  color: #294b86;
}

.faq .faq-list .collapsed .icon-show {
  display: inline-block;
  transition: 0.6s;
}

.faq .faq-list .collapsed .icon-close {
  display: none;
  transition: 0.6s;
}


/*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
.contact .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.3);
  padding: 20px 0 30px 0;
}

.contact .info-box i {
  font-size: 32px;
  color: #294b86;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #b3d1fa;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.4);
  padding: 30px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form .form-group {
  margin-bottom: 20px;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #294b86;
}

.contact .php-email-form input {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type=submit] {
  background: #294b86;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type=submit]:hover {
  background: #3b8af2;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
#footer {
  background: #fff;
  padding: 0 0 30px 0;
  color: #444444;
  font-size: 14px;
  background: #f1f6fe;
}

#footer .footer-newsletter {
  padding: 50px 0;
  background: #f1f6fe;
  text-align: center;
  font-size: 15px;
}

#footer .footer-newsletter h4 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
}

#footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.06);
  text-align: left;
}

#footer .footer-newsletter form input[type=email] {
  border: 0;
  padding: 4px 8px;
  width: calc(100% - 100px);
}

#footer .footer-newsletter form input[type=submit] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #294b86;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-newsletter form input[type=submit]:hover {
  background: #0d58ba;
}

#footer .footer-top {
  padding: 60px 0 30px 0;
  background: #fff;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h3 {
  font-size: 24px;
  margin: 0 0 15px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}

#footer .footer-top .footer-contact h3 span {
  color: #294b86;
}

#footer .footer-top .footer-contact p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  color: #777777;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #444444;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #294b86;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #777777;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: #294b86;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #294b86;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #3b8af2;
  color: #fff;
  text-decoration: none;
}

#footer .copyright {
  text-align: center;
  float: left;
}

#footer .credits {
  float: right;
  text-align: center;
  font-size: 13px;
  color: #444444;
}

@media (max-width: 768px) {

  #footer .copyright,
  #footer .credits {
    float: none;
    text-align: center;
    padding: 2px 0;
  }
}

/* HARI HARAN ADDED CSS START */
.top {
  background-image: url(./images/For_devs/header.jpg);
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;
}

.car {
  padding-top: 175px;
}

.read {
  text-align: center;
  margin-top: 30px;

}

.social i {
  font-size: 40px;
  color: plum;
}

/* HARI HARAN ADDED CSS END */

/* DHIVYA ADDED CSS START*/
#hero .btn-end {
  margin-left: 25px;
}

.about .container .row .col-lg-4 img {
  margin-left: 50px;
  max-width: 50%;
}

.para {
  font-size: 16px;
  /* font-family: sans-serif; */
  font-weight: 300;
}

#list {
  /* font-family: Georgia, 'Times New Roman', Times, serif; */
  font-size: 30px;
  font-style: italic;
}

#list span {
  color: #294b86;
  font-weight: 700;
}

#about span {
  color: #294b86;
}

#about .col-lg-6 .para {
  font-size: 25px;
  /* font-family: 'boxicons'; */
}

/* DHIVYA ADDED CSS END*/

/* John ADDED CSS START */

/* @media only screen and (max-width: 760px){ */

.featured-services-icons .icon {
  margin-bottom: 15px;
  margin-left: 126px;
}


#featured-services h2 span {
  color: #294b86;
}

.about section {
  font-size: 17px;
  line-height: 35px;
  word-spacing: 3px;
}

#about h2 span {
  color: #294b86;
}

#featured-services h3 span {
  color: #294b86;
}

#about h3 span {
  color: #294b86;
}

#team h2 span {
  color: #294b86;
}

#team h3 span {
  color: #294b86;
}




#featured-services h3 span {
  color: #294b86;
}

#service h3 span {
  color: #294b86;
}

.featured-services1 {
  background-color: #f2f2f2;
}


.section-bg-1 {
  margin-top: -42px;
  background-color: #f6f9fe;
  padding-bottom: 0px;
}

.indeximage {
  margin-left: 161px;
}

.image {
  width: 150px;
}


#face {
  width: 60px;
}

#services p {
  font-weight: bold;
}

/* 7/7/23 */
#hire {
  /* background-image: url(../img/hire_remote_dev/head1.jpg); */
  opacity: 0.9;
  height: 75vh;
  background-size: cover;
  background-attachment: fixed;

}

#hire .btn-get-started {
  /* font-family: "Roboto", sans-serif; */
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #294b86;
}

/* job description */

#heros {
  background-image: url(../src/images/home/netbg.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0;
}

#heros h1 span {
  color: #294b86;
}


/*   

/* footer pop up menu */
#popupContainer {
  display: none;
  position: absolute;
  background-color: #343a40;
  color: #0066ff;
  border: 1px solid #ccc;
  padding: 20px;
  z-index: 9999;
  list-style-type: none;
  width: 100%;
  padding-left: 100px;
  padding-right: 100px;
}

#popupContainer ul li {
  list-style-type: none;
}

.popup {
  padding-top: 20px 0px;
}

.popup .close {
  position: fixed !important;
  top: 0;

}

#popupContainer ul a {
  color: white;
  padding: 6px;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#popupContainer ul a:hover {
  color: black;
  text-decoration: none;
}

.popup button {

  /* position: fixed; */
  /* color: whitesmoke !important; */
}

.fadeInUp {
  animation: fadeInUp 0.5s ease backwards;
}

@keyframes fadeInUp {
  0% {
    transform: translate(0px, 100px);
    opacity: 1;
  }

  100% {
    transform: translate(0px, 0);
    opacity: 3;
  }
}


#about span {
  color: #294b86;
}

.about p {
  font-size: 16px;
  line-height: 35px;
}


@media only screen and (max-width: 760px) {
  #featured-services .john {
    margin-left: 65px;
  }
}


#service button {
  background-color: #4285f4;
  color: #ffffff;
  border: none;
  padding: 13px 30px;
  font-size: 1em;
  cursor: pointer;
  width: 50%;
}

.services .icon-box {
  text-align: center;
  border: 1px solid #e2eefd;
  padding: 30px 15px;
  transition: all ease-in-out 0.3s;
  background: #fff;
}

/*  */
/* John ADDED CSS END */



/* new  */
@media only screen and (max-width:655px) {
  .indeximage img {
    width: 340px;
    height: 250px;
  }

  .forimage img {
    width: 340px;
    height: 250px;
    /* display: none; */
  }

  .indeximage {
    margin-left: 0px !important;

  }

  .head {
    margin-top: -25px;
    ;
  }

  .head2 {
    margin-top: 37px;
  }

  .indeximage2 img {
    width: 340px;
    height: 250px;
  }

  .cycleimage img {
    height: 100% !important;
    width: 100% !important;
  }

}

#python {
  background-image: url(./images/job_description/python-desc.jpeg);

  height: 75vh;
  background-size: cover;
  color: black;
  background-attachment: fixed;
  opacity: 0.8;
}

/* job-front end */

#job_front {
  background-image: url(./images/job_description/front_end.jpg);

  height: 75vh;
  background-size: cover;
  color: black;
  background-attachment: fixed;
  opacity: 0.8;
}

#job_front h1 {
  color: white;
  font-size: 4.0rem;
}

/* .front img{} */

#job_script {
  background-image: url(./images/job_description/java_script.jpg);

  height: 75vh;
  background-size: cover;
  color: black;
  background-attachment: fixed;
  opacity: 5;
  position: relative;
}

#job_script:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#java {
  background-image: url(./images/home/javabg.jpg);

  height: 75vh;
  background-size: cover;
  color: black;
  background-attachment: fixed;
  opacity: 0.8;
}



#job_script h1 span {
  color: #294b86;
}

#job_script h1 {
  font-size: 4.0rem;
}


#job_react {
  background-image: url(./images/job_description/reactjs.jpg);

  height: 75vh;
  background-size: cover;
  color: black;
  background-attachment: fixed;
  opacity: 0.8;
}

#job_react h1 span {
  color: #294b86;
}

#job_react .container h1 {
  font-size: 4.0rem;
}


#job_net {
  background-image: url(./images/job_description/Dot-net.jpg);

  height: 75vh;
  background-size: cover;
  color: black;
  background-attachment: fixed;
  opacity: 0.9;
}

#job_net h1 span {
  color: #294b86;
}

#job_net .container h1 {
  font-size: 4.0rem;
}


#job_android {
  background-image: url(./images/home/anroidbg.jpg);

  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;
  opacity: 0.9;
}

h2 span {
  color: #294b86;
}

#job_android .container {
  font-size: 4.0rem;
}


#job_aws {
  background-image: url(./images/home/awsbg.jpg);

  height: 75vh;
  background-size: cover;
  color: black;
  background-attachment: fixed;
  opacity: 0.9;
}

#job_aws h1 span {
  color: #294b86;
}

#job_aws .container h1 {
  font-size: 4.0rem;
}


.heros h2 span {
  color: #294b86;

}

.blue {
  color: #294b86;
}

.career {
  font-size: 4.0rem;
}

#careers {
  font-size: 16px;
}

#vueheader {
  background-image: url(./images/job_description/vue_header.jpg);
  height: 75vh;
  background-size: cover;
  background-attachment: fixed;
  opacity: 1.0;
}

#vueheader h1 {
  font-size: 4.0rem;
  color: black;
}

#vueheader h1 span {
  color: #294b86;
}

#vueheader h2 span {
  color: #294b86;
}


/* backend */
#backend {
  background-image: url(./images/job_description/vue_header.jpg);
  height: 75vh;
  background-size: cover;
  background-attachment: fixed;
  opacity: 1.0;
}

#blockchains {
  background-image: url(./images/job_description/senior.webp);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0;
}

#blockchains h1 {
  font-size: 4.0rem;
  color: white;

}


/* / .........job-react native developer start............. / */
#reactnative {
  background-image: url(./images/job_description/react_background.jpeg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0;
}

#reactnative h1 {
  font-size: 4.0rem;
  color: black;

}

#reactnative h1 span {
  color: #294b86;
}

#reactnative {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#reactnative:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#reactnative .container {
  position: relative;
}

/* / .........job-react native developer end............. / */
/* / sql / */
#sql {
  background-image: url(./images/job_description/data-scientist.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0;
}

#sql h1 {
  font-size: 4.0rem;
  color: white;
}

#sql h1 span {
  color: #294b86;
}

#cover h2 span {
  color: #294b86;
}

#startwriting h2 span {
  color: #294b86;
}

#broader h2 span {
  color: #294b86;
}

#previous h2 span {
  color: #294b86;
}

#pharmaceutical h2 span {
  color: #294b86;
}


/* / full_stack / */
#stack {
  background-image: url(./images/job_description/html.jpg);
  height: 75vh;
  background-size: cover;
  background-attachment: fixed;
  opacity: 1.0;
}

#stack h1 {
  font-size: 4.0rem;
  color: block;

}

#stack h1 span {
  color: #294b86;
}

#stack {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#stack:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#stack .container {
  position: relative;
}

/* / ..........job-full_stack end........... / */


/* / ...........job-data scientist start...........  / */
#datascientist {
  background-image: url(./images/job_description/data-scientist.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0;
}

#datascientist h1 {
  font-size: 4.0rem;
  color: black;
}

#datascientist h1 span {
  color: #294b86;
}

#discovery h2 span {
  color: #294b86;
}

#crucial h2 span {
  color: #294b86;
}

#explorator h2 span {
  color: #294b86;
}

#datascientist {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#datascientist:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#datascientist.container {
  position: relative;
}

/* / ...........job-data scientist end...........  / */
/* / devops engineer / */
#devpsengineer {
  background-image: url(./images/job_description/devops.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0;
}

#devpsengineer h1 {
  font-size: 4.0rem;
  color: black;
}

#devpsengineer h1 span {
  color: #294b86;
}

/* /.............job-html-developer start........... / */
#htmldeveloper {
  background-image: url(./images/home/databg.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

#htmldeveloper h1 span {
  color: #294b86;
}

#htmldeveloper h1 {
  font-size: 4.0rem;
  color: black;
}

.container #distinctive h2 span {
  color: #294b86;
}

#position h2 span {
  color: #294b86;
}

#Include h2 span {
  color: #294b86;
}

#Enumerate h2 span {
  color: #294b86;
}

#sweetest h2 span {
  color: #294b86;
}

#htmldeveloper {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#htmldeveloper:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#htmldeveloper.container {
  position: relative;
}

#DevOps h2 span {
  color: #294b86;
}

#principles h2 span {
  color: #294b86;
}

#Mention h2 span {
  color: #294b86;
}

#devpsengineer {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#devpsengineer:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#devpsengineer.container {
  position: relative;
}

/* / sql / */



/* /.............job-html-developer start........... / */
#htmldeveloper {
  background-image: url(./images/job_description/html.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

#htmldeveloper h1 span {
  color: #294b86;
}

#htmldeveloper h1 {
  font-size: 4.0rem;
  color: black;
}

.container #distinctive h2 span {
  color: #294b86;
}

#position h2 span {
  color: #294b86;
}

#Include h2 span {
  color: #294b86;
}

#Enumerate h2 span {
  color: #294b86;
}

#sweetest h2 span {
  color: #294b86;
}

#htmldeveloper {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#htmldeveloper:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#htmldeveloper.container {
  position: relative;
}

/* /.............job-html-developer end........... / */


/* / .............job-AI engineer start......... / */

#devpsengineer h1 span {
  color: #294b86;
}

#DevOps h2 span {
  color: #294b86;
}

#principles h2 span {
  color: #294b86;
}

#Mention h2 span {
  color: #294b86;
}

/* / sql / */


#aiengineer h1 {
  font-size: 4.0rem;
  color: black;
}

#aiengineer h1 span {
  color: #294b86;
}

#template h2 span {
  color: #294b86;
}

#Intelligence h2 span {
  color: #294b86;
}

#detail h2 span {
  color: #294b86;
}

#paragraph h2 span {
  color: #294b86;
}

#reward h2 span {
  color: #294b86;
}

#pharmaceutical h2 span {
  color: #294b86;
}

#machin h2 span {
  color: #294b86;
}

#aiengineer {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#aiengineer:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#aiengineer.container {
  position: relative;
}

/* / .............job-AI engineer end......... / */


/* /..............job-angular developer start............... / */
#angular {
  background-image: url(./images/job_description/angular.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

#angular h1 {
  font-size: 4.0rem;
  color: black;
}

#angular h1 span {
  color: #294b86;
}

#organization h2 span {
  color: #294b86;
}

#searching h2 span {
  color: #294b86;
}

#technical h2 span {
  color: #294b86;
}

#honest h2 span {
  color: #294b86;
}

#growth h2 span {
  color: #294b86;
}

#angular {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#angular:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#angular.container {
  position: relative;
}

/* /..............job-angular developer end............... / */


/* / .........job application developer start....... / */
#application {
  background-image: url(./images/home/applicationbg.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

#application h1 {
  font-size: 4.0rem;
  color: white;
}

#application {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#application:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#application.container {
  position: relative;
}

/* / .........job application developer end....... / */


/* / ..........job c++ developer start........ / */
#cdeveloper {
  background-image: url(./images/home/cplus.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

#cdeveloper h1 {
  font-size: 4.0rem;
  color: black;
}

#cdeveloper {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#cdeveloper:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#cdeveloper.container {
  position: relative;
}

/* / ..........job c++ developer end........ / */


/* / ..........job c++ developer start........ / */
#cdeveloper {
  background-image: url(./images/job_description/angular.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

#cdeveloper h1 {
  font-size: 4.0rem;
  color: black;
}

#cdeveloper {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#cdeveloper:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#cdeveloper.container {
  position: relative;
}

/* / ..........job c++ developer end........ / */


/* / .........ios developer start......... / */
#iosdeveloper {
  background-image: url(./images/job_description/data-scientist.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

#iosdeveloper h1 {
  font-size: 4.0rem;
  color: black;
}

/* / .........ios developer end......... / */

/* .........job-node start........... */
#Node {
  background-image: url(./images/job_description/html.jpg);
  height: 75vh;
  background-size: cover;
  background-attachment: fixed;
  opacity: 1.0
}

#Node h1 {
  font-size: 4.0rem;
  color: black;
}

#Node {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#Node:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#Node.container {
  position: relative;
}

/* .........job-node end........... */



/* / senior developer / */
#senior {
  background-image: url(./images/job_description/Dot-net.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

#htmldeveloper h1 span {
  color: #294b86;
}

#htmldeveloper h1 {
  font-size: 4.0rem;
  color: black;
}

.container #distinctive h2 span {
  color: #294b86;
}

#position h2 span {
  color: #294b86;
}

#Include h2 span {
  color: #294b86;
}

#Enumerate h2 span {
  color: #294b86;
}

#sweetest h2 span {
  color: #294b86;
}

/* / AI engineer / */

#aiengineer {
  background-image: url(./images/job_description/aiengineer.jpg);
  height: 75vh;
  width: 100% !important;
  background-size: cover;
  object-fit: cover !important;
  background-attachment: fixed;
  opacity: 1.0;
  position: relative;
}

#aiengineer:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}


#aiengineer h1 {
  font-size: 4.0rem;
  color: black;
}

#aiengineer h1 span {
  color: #294b86;
}

#template h2 span {
  color: #294b86;
}

#Intelligence h2 span {
  color: #294b86;
}

#detail h2 span {
  color: #294b86;
}

#paragraph h2 span {
  color: #294b86;
}

#reward h2 span {
  color: #294b86;
}

#pharmaceutical h2 span {
  color: #294b86;
}

#machin h2 span {
  color: #294b86;
}

/* / angular developer / */
#angular {
  background-image: url(./images/home/angularbg.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

#angular h1 {
  font-size: 4.0rem;
  color: black;
}

#angular h1 span {
  color: #294b86;
}

#organization h2 span {
  color: #294b86;
}

#searching h2 span {
  color: #294b86;
}

#technical h2 span {
  color: #294b86;
}

#honest h2 span {
  color: #294b86;
}

#growth h2 span {
  color: #294b86;
}

/* / application developer / */
#application {
  background-image: url(./images/job_description/front_end.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

#application h1 {
  font-size: 4.0rem;
  color: black;
}

#application h1 span {
  color: #294b86;
}

#easier h2 span {
  color: #294b86;
}

#criteria h2 span {
  color: #294b86;
}

#applicant h2 span {
  color: #294b86;
}

#linked h2 span {
  color: #294b86;
}

#bonuses h2 span {
  color: #294b86;
}

/* / c++ developer / */


#cdeveloper h1 {
  font-size: 4.0rem;
  color: black;
}

#cdeveloper h1 span {
  color: #294b86;
}

#history h2 span {
  color: #294b86;
}

#associated h2 span {
  color: #294b86;
}

#outlining h2 span {
  color: #294b86;
}

#knowledge h2 span {
  color: #294b86;
}

#drafting h2 span {
  color: #294b86;
}

/* / ios developer / */
#iosdeveloper {
  background-image: url(./images/home/iosbg.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

#iosdeveloper h1 {
  font-size: 4.0rem;
  color: black;
}

#iosdeveloper h1 span {
  color: #294b86;
}

h2 span {
  color: #294b86;
}

/* / senior developer / */
#senior {
  background-image: url(./images/job_description/Dot-net.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

#senior h1 {
  font-size: 4.0rem;
  color: white;
}

#senior h1 span {
  color: #294b86;
}

h2 span {
  color: #294b86;
}

/* / unity3d developer / */
#unitys {
  background-image: url(./images/home/unity.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

#unitys h1 {
  font-size: 4.0rem;
  color: black;
}

#unitys h1 span {
  color: #294b86;
}

h2 span {
  color: #294b86;
}



/* node */
#Node {
  background-image: url(./images/home/nodebgg.jpg);
  height: 75vh;
  background-size: cover;
  background-attachment: fixed;

}

/* / wordpress developer / */
#wordpress {
  background-image: url(./images/home/wordbg.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

#wordpress h1 {
  font-size: 4.0rem;
  color: black;
}

#wordpress h1 span {
  color: #294b86;
}

#specialization h2 span {
  color: #294b86;
}

#fixing h2 span {
  color: #294b86;
}

#architecture h2 span {
  color: #294b86;
}

#Keen h2 span {
  color: #294b86;
}

/* / embedded developer / */
#embedded {
  background-image: url(./images/job_description/angular.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

#embedded h1 {
  font-size: 4.0rem;
  color: black;
}

#embedded h1 span {
  color: #294b86;
}

#both h2 span {
  color: #294b86;
}

#Swedish h2 span {
  color: #294b86;
}

#tree h2 span {
  color: #294b86;
}

#microcontroller h2 span {
  color: #294b86;
}

#average h2 span {
  color: #294b86;
}

#newcomers h2 span {
  color: #294b86;
}

/* / ui/ux designer / */
#udesigner {
  background-image: url(./images/home/uibg.jpg.crdownload);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

#udesigner h1 {
  font-size: 4.0rem;
  color: black;
}

#udesigner h1 span {
  color: #294b86;
}

#rebrandings h2 span {
  color: #294b86;
}

#strengths h2 span {
  color: #294b86;
}

#concise h2 span {
  color: #294b86;
}

#stress h2 span {
  color: #294b86;
}

#sort h2 span {
  color: #294b86;
}

#insignificant h2 span {
  color: #294b86;
}

/* / qa-engineer / */
#qadev {

  background-image: url(./images/job_description/vue_header.jpg);

  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

#qadev h1 {
  font-size: 4.0rem;
  color: black;
}

#qadev h1 span {
  color: #294b86;
}

#comprehend h2 span {
  color: #294b86;
}

#Cyberhaven h2 span {
  color: #294b86;
}

#overlap h2 span {
  color: #294b86;
}

#versatile h2 span {
  color: #294b86;
}

#tailored h2 span {
  color: #294b86;
}

#logical h2 span {
  color: #294b86;
}

/* / flutter-dev / */
#flutter {

  background-image: url(./images/home/flutterbg.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

#flutter h1 {
  font-size: 4.0rem;
  color: black;
}

#flutter h1 span {
  color: #294b86;
}

#Moreover h2 span {
  color: #294b86;
}

#collaboration h2 span {
  color: #294b86;
}

#independently h2 span {
  color: #294b86;
}

#methodologies h2 span {
  color: #294b86;
}

#logie h2 span {
  color: #294b86;
}

#demonstrating h2 span {
  color: #294b86;
}

/* / software engineer / */
#softwares {
  /* / NOT ADD IMG / */
  background-image: url(./images/job_description/unity.jpeg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

#bottomless h1 {
  font-size: 4.0rem;
  color: black;
}

#bottomless h1 span {
  color: #294b86;
}

h2 span {
  color: #294b86;
}

.Effortless {
  color: #294b86;
}


/* / machine developer / */
#machines {
  /* / NOT ADD IMG / */
  background-image: url(./images/home/mlbg.jpg.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

#machines h1 {
  font-size: 4.0rem;
  color: black;
}

#valuabl h2 span {
  color: #294b86;
}

#informative h2 span {
  color: #294b86;
}

#routine h2 span {
  color: #294b86;
}

#miscommunications h2 span {
  color: #294b86;
}

#memberships h2 span {
  color: #294b86;
}

/* / database dev  / */
#databasedev {
  /* / NOT ADD IMG / */
  background-image: url(./images/home/dbbg.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

#databasedev h1 {
  font-size: 4.0rem;
  color: black;
}

#databasedev h1 span {
  color: #294b86;
}

#ultimat h2 span {
  color: #294b86;
}

#accmplishment h2 span {
  color: #294b86;
}

#integri h2 span {
  color: #294b86;
}

#bulle h2 span {
  color: #294b86;
}

#drafti h2 span {
  color: #294b86;
}

#insuran h2 span {
  color: #294b86;
}

/* / data analyts  / */
#dataanalyst {
  /* / NOT ADD IMG / */
  background-image: url(./images/home/analystbg.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

#dataanalyst h1 {
  font-size: 4.0rem;
  color: black;
}

#dataanalyst h1 span {
  color: #294b86;
}

#devotr h2 span {
  color: #294b86;
}

#fragment h2 span {
  color: #294b86;
}

#numerate h2 span {
  color: #294b86;
}

#collabo h2 span {
  color: #294b86;
}

#reliev h2 span {
  color: #294b86;
}

#rinsuran h2 span {
  color: #294b86;
}

/* / cicd engineer / */
#cicdengineer {
  /* / NOT ADD IMG / */
  background-image: url(./images/home/javabg.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

#cicdengineer h1 {
  font-size: 4.0rem;
  color: black;
}

#cicdengineer h1 span {
  color: #294b86;
}

#financ h2 span {
  color: #294b86;
}

#nterv h2 span {
  color: #294b86;
}

#characteri h2 span {
  color: #294b86;
}

#gration h2 span {
  color: #294b86;
}

#argration h2 span {
  color: #294b86;
}

#Bonu h2 span {
  color: #294b86;
}

/* / blackchain / */
#blackchains {
  /* / NOT ADD IMG / */
  background-image: url(./images/job_description/Dot-net.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

#blackchains h1 {
  font-size: 4.0rem;
  color: black;
}

#blackchains h1 span {
  color: #294b86;
}

#financer h2 span {
  color: #294b86;
}

#intervies h2 span {
  color: #294b86;
}

#degagration h2 span {
  color: #294b86;
}

#corpor h2 span {
  color: #294b86;
}

#babys h2 span {
  color: #294b86;
}

/* / scala dev  / */
#scaladev {
  /* / NOT ADD IMG / */
  background-image: url(./images/home/flutterbg.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

#scaladev h1 {
  font-size: 4.0rem;
  color: black;
}

#scaladev h1 span {
  color: #294b86;
}

#lture h2 span {
  color: #294b86;
}

#rank h2 span {
  color: #294b86;
}

#suita h2 span {
  color: #294b86;
}

#ividua h2 span {
  color: #294b86;
}

#roubl h2 span {
  color: #294b86;
}

#urances h2 span {
  color: #294b86;
}

/* / senior software / */
#seniors {
  /* / NOT ADD IMG / */
  background-image: url(./images/home/seniorbg.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

#seniors h1 {
  font-size: 4.0rem;
  color: black;
}

#seniors h1 span {
  color: #294b86;
}

#thrive h2 span {
  color: #294b86;
}

#recruitinge h2 span {
  color: #294b86;
}

#whether h2 span {
  color: #294b86;
}

#indication h2 span {
  color: #294b86;
}

#determine h2 span {
  color: #294b86;
}

#demonstrate h2 span {
  color: #294b86;
}

/* / content deve  / */
#contendev {

  background-image: url(./images/job_description/devops.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

#contendev h1 {
  font-size: 4.0rem;
  color: black;
}

#contendev h1 span {
  color: #294b86;
}

h2 span {
  color: #294b86;
}

#Ruby {
  background-image: url(./images/home/dd.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

.poptop {
  position: relative;
  bottom: 0;
  display: none;
}

.popup {

  animation: example 0.3s linear 0s;
  animation-fill-mode: forwards;
  transform: translateY(102px);

  position: absolute;
  background-color: #343a40;
  bottom: 10px;
  overflow: scroll;
  overflow: hidden;
  z-index: 9999;
  width: 100%;

  font-size: 17px;
  text-decoration: none;
}

@keyframes example {
  from {
    bottom: 0
  }

  to {
    bottom: 100px;
  }
}

.footer-links a {
  cursor: pointer;
}

.poptop #pop ul li {
  list-style: none;
}

.poptop #pop ul li a {

  color: white !important;
}


.section .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.section .content ul {
  list-style: none;
  padding: 0;
}

.section .content ul li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 35px;
}

.section .content ul li:first-child {
  margin-top: 35px;
}

.section .content ul i {
  background: #fff;
  box-shadow: 0px 6px 15px rgba(16, 110, 234, 0.12);
  font-size: 24px;
  padding: 20px;
  line-height: 1;
  margin-right: 23px;
  color: #294b86;
  border-radius: 50px;
}

.section .content ul h5 {
  font-size: 14px;
  color: #555555;
}

.section .content ul p {
  font-size: 13px;
}

.section .content p:last-child {
  margin-bottom: 0;
}

/* Hire Angular */

#Hireangular {

  background-image: url(./images/job_description/devops.jpg);
  height: 75vh;
  background-size: cover;
  background-attachment: fixed;
  opacity: 1.0;
  color: white !important;
}


#Hireangular {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#Hireangular:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#Hireangular.container {
  position: relative;
}

#angular1 p {
  line-height: 25px;
  word-spacing: 7px;
  font-size: 15px;
}

#ang {
  line-height: 25px;
  word-spacing: 7px;
  font-size: 15px;
}

.Speeded {
  background-color: #f2f2f2;
}

/* ............................................................... */
/* Mongodb */
#Mongodb {

  background-image: url(./images/job_description/devops.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

/* typescript */
#Typescript {

  background-image: url(./images/job_description/angular.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

/* Cards */


.container {
  max-width: 950px;
  width: 100%;
  overflow: hidden;
  /* padding: 80px 0; */
}

.container .main-card {
  display: flex;
  justify-content: space-evenly;
  width: 200%;
  transition: 1s;
}

#two:checked~.main-card {
  margin-left: -100%;
}

.container .main-card .cards {
  width: calc(100% / 2 - 10px);
  display: flex;
  flex-wrap: wrap;
  margin: 0 20px;
  justify-content: space-between;
}

.main-card .cards .card {
  width: calc(100% / 3 - 10px);
  background: #fff;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.4s ease;
}

.main-card .cards .card:hover {
  transform: translateY(-15px);
}

.cards .card .content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.cards .card .content .img {
  height: 130px;
  width: 130px;
  border-radius: 50%;
  padding: 3px;
  background: #294b86;
  margin-bottom: 14px;
}

.card .content .img img {
  height: 100%;
  width: 100%;
  border: 3px solid #ffff;
  border-radius: 50%;
  object-fit: cover;
}

.card .content .name {
  font-size: 20px;
  font-weight: 500;
}

.card .content .job {
  font-size: 20px;
  color: #294b86;
}

.card .content .media-icons {
  margin-top: 10px;
  display: flex;

}

.media-icons a {
  text-align: center;
  line-height: 33px;
  height: 35px;
  width: 35px;
  margin: 0 4px;
  font-size: 14px;
  color: #FFF;
  border-radius: 50%;
  border: 2px solid transparent;
  background: #294b86;
  transition: all 0.3s ease;
}

.media-icons a:hover {
  color: #294b86;
  background-color: #fff;
  border-color: #294b86;
}

.container .button {
  width: 100%;
  /* display: flex; */
  justify-content: center;
  margin: 20px;
}

.button label {
  height: 15px;
  width: 15px;
  border-radius: 20px;
  background: #fff;
  margin: 0 4px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.button label.active {
  width: 35px;
}

#one:checked~.button .one {
  width: 35px;
}

#one:checked~.button .two {
  width: 15px;
}

#two:checked~.button .one {
  width: 15px;
}

#two:checked~.button .two {
  width: 35px;
}

input[type="radio"] {
  display: none;
}

@media (max-width: 768px) {
  .main-card .cards .card {
    margin: 20px 0 10px 0;
    width: calc(100% / 2 - 10px);
  }
}

@media (max-width: 600px) {
  .main-card .cards .card {

    width: 100%;
  }
}


h2 span {
  color: #294b86;
}

/* hire_aws */
#hireaws {
  background-image: url(./images/hire_devs/aws_inside.webp);

  height: 75vh;
  background-size: cover;
  color: black;
  background-attachment: fixed;
  opacity: 0.8;
}

#hireaws {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#hireaws:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hireaws.container {
  position: relative;
}

#hireaws h1 span {
  color: #294b86;
}

#hireaws h1 {
  font-size: 4.0rem;
  color: white;
}

#hireaws p {
  color: white;
}

#facered {
  width: 35%;
}

#container-red {
  color: #f6f9fe;
}

#ecruit {
  background-color: #f6f9fe;
}

.unclass {
  background-color: #f2f2f2;
}

#firing {
  background-color: #f6f9fe;
}

#avener {
  background-color: #f2f2f2;
}

.requed {
  background-color: #f2f2f2;
}

/* / hire_ios dev / */
h2 span {
  color: #294b86;
}

#hireeios {
  background-image: url(./images/hire_devs/front_end.jpg);

  height: 75vh;
  background-size: cover;
  color: black;
  background-attachment: fixed;
  opacity: 0.8;
}

#hireeios h1 {
  color: white;
  font-size: 4.0rem;
}

#hireeios h1 span {
  color: #294b86;
}

#hireeios p {
  color: white;
}

#nuller {
  width: 35%;
}

.crowdsourcinger {
  background-color: #f6f9fe;
}

.Secuer {
  background-color: #f2f2f2;
}

#videssder {
  background-color: #f2f2f2;
}

/* / hire_.net dev / */
#hireenet {
  background-image: url(./images/hire_devs/hire_netdev.jpg);

  height: 75vh;
  background-size: cover;
  color: black;
  background-attachment: fixed;
  opacity: 0.8;
}

#hireenet h1 {
  color: white;
  font-size: 4.0rem;
}

#hireenet h1 span {
  color: #294b86;
}

#hireenet p {
  color: white;
}

h2 span {
  color: #294b86;
}

#hireenet {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#hire t:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hireenet .container {
  position: relative;
}

.furtheess {
  background-color: #f2f2f2;
}

.bestfitessed {
  background-color: #f2f2f2;
}

.suplim {
  background-color: #f2f2f2;
}

/* / hire_andriod  / */
#Difficulttower tr,
th,
td {
  border: 1px solid;
}

#hireeandriod {
  background-image: url(./images/hire_devs/hire_Android.jpg);
  height: 75vh;
  background-size: cover;
  color: black;
  background-attachment: fixed;
  opacity: 0.8;
}

#hireeandriod h1 {
  color: white;
  font-size: 4.0rem;
}

#hireeandriod h1 span {
  color: #294b86;
}

#hireeandriod p {
  color: white;
}

#hireeandriod {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#hireeandriod:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hireeandriod.container {
  position: relative;
}

h4 span {
  color: #294b86;
}

.speakingedAndroid {
  background-color: #f2f2f2;
}

.hireanwer {
  background-color: #f2f2f2;
}

.costeffective {
  background-color: #f6f9fe;
}

/* / hire_php / */

.aboutphp {
  font-size: 15px;
  line-height: 40px;
}

#hireephp {
  background-image: url(./images/hire_devs/hire_newphp.jpg);
  height: 75vh;
  background-size: cover;
  color: black;
  background-attachment: fixed;
  opacity: 0.8;
}

#hireephp h1 {
  color: white;
  font-size: 4.0rem;
}

#hireephp h1 span {
  color: #294b86;
}

#hireephp p {
  color: white;
}

#hireephp {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#hireephp:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hireephp .container {
  position: relative;
}

#anwar {
  background-color: #f2f2f2;
}

.Onlyvetteder {
  background-color: #f2f2f2;
}

/* / why us  / */
.why-us .content {
  padding: 60px 100px 0 100px;
}

.why-us .content h3 {
  font-weight: 400;
  font-size: 34px;
  color: #37517e;
}

.why-us .content h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 5px;
}

.why-us .content p {
  font-size: 15px;
  color: #848484;
}

.why-us .img {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.why-us .accordion-list {
  padding: 0 100px 42px 100px;
}

.why-us .accordion-list ul {
  padding: 0;
  list-style: none;

}

.why-us .accordion-list li+li {
  margin-top: 15px;
}

.why-us .accordion-list li {

  background: #fff;
  border-radius: 13px;
  padding: 10px;

}

.why-us .accordion-list a {
  display: block;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding-right: 30px;
  outline: none;
  cursor: pointer;
  text-decoration: none;

}

.why-us .accordion-list span {
  color: #47b2e4;
  font-weight: 600;
  font-size: 18px;
  padding-right: 10px;
}

.why-us .accordion-list i {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;

}

.why-us .accordion-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.why-us .accordion-list .icon-show {
  display: none;

}

.why-us .accordion-list a.collapsed {
  color: #343a40;
  visibility: visible;

}


.why-us .accordion-list a.collapsed:hover {
  color: #47b2e4;
}

.why-us .accordion-list a.collapsed .icon-show {
  display: inline-block;
}

.why-us .accordion-list a.collapsed .icon-close {
  display: none;
}


@media (max-width: 1024px) {

  .why-us .content,
  .why-us .accordion-list {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 992px) {
  .why-us .img {
    min-height: 400px;
  }

  .why-us .content {
    padding-top: 30px;
  }

  .why-us .accordion-list {
    padding-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .why-us .img {
    min-height: 200px;
  }
}



/* ...........hire-ecommerce start................ */
.reactop2 {
  background-image: url(./images//hire_devs/ecommerce.jpg);
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;
}

.advantageede {
  background-color: #f2f2f2;
}

/* ...........hire-ecommerce end................ */
.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 15px auto;
  list-style: none;
  text-align: center;
  border-radius: 50px;
  padding: 2px 15px;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 10px 15px 8px 15px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

.skills .progress {
  height: 60px;
  display: block;
  background: none;
  border-radius: 0;
}

.bi-chevron-down::before {
  content: "\f282";
  padding-top: 10px !important;
  padding-left: 10px;
}

#hirenative {
  background-image: url(./images/For_devs/arc.jpg);
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;
}

#hireflutter {
  background-image: url(./images/For_devs/bgflutter.jpg);
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;
}

.reactop3 {
  background-image: url(./images//hire_devs/ecommerce.jpg);
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;
}

.skills .progress .skill {
  padding: 0;
  margin: 0 0 6px 0;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #37517e;
}

.skills .progress .skill .val {
  float: right;
  font-style: normal;
}

.skills .progress-bar-wrap {
  background: #e8edf5;
  height: 10px;
}

.skills .progress-bar {
  width: 1px;
  height: 10px;
  transition: 0.9s;
  background-color: #4668a2;
}

.skills .content h3 {
  font-weight: 700;
  font-size: 32px;
  color: #37517e;
  font-family: "Poppins", sans-serif;
}

.skills .content ul {
  list-style: none;
  padding: 0;
}

.skills .content ul li {
  padding-bottom: 10px;
}

.skills .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #47b2e4;
}

.skills .content p:last-child {
  margin-bottom: 0;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: #294b86;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio .portfolio-item .portfolio-info {
  opacity: 0;
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px;
}

.portfolio .portfolio-item .portfolio-info h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  color: #222222;
}

.portfolio .portfolio-item .portfolio-info p {
  color: #555555;
  font-size: 14px;
  margin-bottom: 0;
}

.portfolio .portfolio-item .portfolio-info .preview-link,
.portfolio .portfolio-item .portfolio-info .details-link {
  position: absolute;
  right: 40px;
  font-size: 24px;
  top: calc(50% - 18px);
  color: #3c3c3c;
}

.portfolio .portfolio-item .portfolio-info .preview-link:hover,
.portfolio .portfolio-item .portfolio-info .details-link:hover {
  color: #294b86;
}

.portfolio .portfolio-item .portfolio-info .details-link {
  right: 10px;
}

.portfolio .portfolio-item .portfolio-links {
  opacity: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 3;
  position: absolute;
  transition: all ease-in-out 0.3s;
}

.portfolio .portfolio-item .portfolio-links a {
  color: #fff;
  margin: 0 2px;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-item .portfolio-links a:hover {
  color: #6ba7f5;
}

.portfolio .portfolio-item:hover .portfolio-info {
  opacity: 1;
  bottom: 20px;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #294b86;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #294b86;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(34, 34, 34, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}


.container h1 {
  font-size: 4.5rem;
  color: black;
  margin-bottom: 10px;
}

.container2 h1 {
  font-size: 4.5rem;
  color: black;

}

.container h1 span {
  color: #294b86;
}

.container2 h1 span {
  color: #294b86;
}

.container h2 span {
  color: #294b86;
}

.top {
  background-image: url(./images/For_devs/header.jpg);
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;
}

.reactop {
  background-image: url(./images/hire_devs/keyboard.jpg);
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;
}

/* .................hire-ecomeress start ..................... */
/* #reactop2{
background-image: url(./images//hire_devs/ecommerce.jpg);
height: 75vh;
background-size: cover; 
color:white;
background-attachment: fixed;
}

#reacttop2 h1 {
  font-size: 4.0rem;
  color: white;

} */
#reactop2 {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#reactop2 :before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#reactop2.container {
  position: relative;
}

/* .................hire-ecomeress end ..................... */


/* .................hire-devpos start.................. */
#reactop4 {
  background-image: url(./images/For_devs/bg.jpg);
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;
}

#reactop4 h1 {
  color: white;
  font-size: 4.0rem;
}

/* #reactop4 {
    
    width: 100%;
    height: 75vh;
   
    background-size: cover;
    opacity:5 ;
    position: relative;
  }
  
  #reactop4 :before {
    content: "";
    background: rgb(193 193 193 / 60%);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  
  #reactop4.container {
    position: relative;
  } */
/* .................hire-devpos end.................. */

/* .............hire_python start................. */


#hireepython {
  background-image: url(./images/hire_devs/fb.jpg);
  height: 75vh;
  background-size: cover;
  color: black;
  background-attachment: fixed;
  opacity: 0.8;
}

#hireepython h1 {
  color: white;
  font-size: 4.0rem;
}

#hireepython {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

/* #hireepython :before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
} */

#hireepython.container {
  position: relative;
}

/* .............hire_python end................. */


/* .........hire_frontend  start........*/
#hireefrontend {
  background-image: url(./images/hire_devs/hire_frontend.jpg);
  height: 75vh;
  background-size: cover;
  color: black;
  background-attachment: fixed;
  opacity: 0.8;
}

#hireefrontend h1 {
  color: black;
  font-size: 4.0rem;
}

.thered {
  background-color: #f2f2f2;
}

/* #hireefrontend {
  
  width: 100%;
  height: 75vh;
 
  background-size: cover;
  opacity:5 ;
  position: relative;
}

#hireefrontend :before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hireefrontend .container {
  position: relative;
} */
/* .........hire_frontend  end........*/


/* / ..........hire_part_time start......... / */
#hireeparttime {
  background-image: url(./images/hire_devs/part_time.jpg);
  height: 75vh;
  background-size: cover;
  color: black;
  background-attachment: fixed;
  opacity: 0.8;
}

#hireeparttime h1 {
  color: white;
  font-size: 4.0rem;
}

#hireeparttime {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#hireeparttime:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hireeparttime.container {
  position: relative;
}

.dedicapar {
  background-color: #f2f2f2;
}

.hiredues {
  background-color: #f2f2f2;
}

.lized {
  background-color: #f2f2f2;
}

/* / ..........hire_part_time end......... / */


/* / ............hire_mobile app start...............  / */
#hireemobileapp {
  background-image: url(./images/hire_devs/MOBILE_APP.jpg);
  height: 75vh;
  background-size: cover;
  color: black;
  background-attachment: fixed;
  opacity: 0.8;
}

#hireemobileapp h1 {
  color: white;
  font-size: 4.0rem;
}

#hireeparttime h1 span {
  color: #294b86;
}

#hireemobileapp {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#hireemobileapp:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hireemobileapp .container {
  position: relative;
}

/* / ............hire_mobile app end...............  / */


#hireeparttime p {
  color: white;
}

.rojed {
  background-color: #f2f2f2;
}

/* / hire_mobile app  / */
#hireemobileapp {
  background-image: url(./images/hire_devs/MOBILE_APP.jpg);
  height: 75vh;
  background-size: cover;
  color: black;
  background-attachment: fixed;
  opacity: 0.8;
}

#hireemobileapp h1 {
  color: white;
  font-size: 4.0rem;
}

#hireemobileapp h1 span {
  color: #294b86;
}

#hireemobileapp p {
  color: white;
}

#saastop {
  background-image: url(./images/hire_devs/devops.jpg);
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;
}

#saastop h1 {
  color: white;
  font-size: 4.0rem;
}

/* #saas {
  box-shadow: 0px 20px 25px rgb(0 0 0 / 12%);

} */

/* #saas img {
  width: fit-content;
} */

/* #saastop {
  
  width: 100%;
  height: 75vh;
 
  background-size: cover;
  opacity:5 ;
  position: relative;
}

#saastop :before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#saastop .container {
  position: relative;
} */
/* / ............hire_saas end...............  / */

/* / ............hire_jquery start...............  / */
#hirejquery {
  background-image: url(./images//hire_devs/jquery.jpg);
  width: 100%;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;

}

#hirejquery h1 {
  color: black;
  font-size: 4.0rem;
}

.roder {
  background-color: #f2f2f2;
}

.Oner {
  background-color: #f2f2f2;
}

/* #hirejquery {
  
  width: 100%;
  height: 75vh;
 
  background-size: cover;
  opacity:5 ;
  position: relative;
}

#hirejquery :before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hirejquery .container {
  position: relative;
} */
/* / ............hire_jquery end...............  / */


/*........................... hire-vue start............................ */
#hirevue {
  background-image: url(./images/hire_devs/Full-stack-developer.png);
  width: 100% !important;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;

}

#hirevue h1 {
  color: black;
  font-size: 4.0rem;
}

/* #hirevue {
  
  width: 100%;
  height: 75vh;
 
  background-size: cover;
  opacity:5 ;
  position: relative;
} */

/* #hirevue :before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hirevue .container {
  position: relative;
} */
/*........................... hire-vue end............................ */

/*........................... hire-ruby start............................ */
#hireruby {
  background-image: url(./images/hire_devs/ror_banner.jpg);
  width: 100%;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;

}

#hireruby h1 {
  color: black;
  font-size: 4.0rem;
}

#hireruby {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#hireruby:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hireruby .container {
  position: relative;
}

/*........................... hire-ruby start............................ */

/*.................hire-senior start............................ */
#hiresenior {
  background-image: url(./images/hire_devs/senior.jpg);
  width: 100%;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;

}

#hiresenior h1 {
  color: black;
  font-size: 4.0rem;
}

#hiresenior {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#hiresenior:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hiresenior.container {
  position: relative;
}

/*.................hire-senior end............................ */

/*.................hire-senior android start............................ */
#hireseniorandroid {
  background-image: url(./images/hire_devs/senior-android.png);
  width: 100%;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;

}

#hireseniorandroid h1 {
  color: black;
  font-size: 4.0rem;
}

#hireseniorandroid {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#hireseniorandroid:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hireseniorandroid.container {
  position: relative;
}

/*.................hire-senior android end............................ */


/*.................hire-lead start............................ */
#hirelead {
  background-image: url(./images/hire_devs/lead.jpg);
  width: 100%;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;

}

#hirelead h1 {
  color: black;
  font-size: 4.0rem;
}

#hirelead {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#hirelead:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hirelead.container {
  position: relative;
}

/*.................hire-lead end............................ */
#hirestartup {
  background-image: url(./images/hire_devs/startup.png);
  width: 100%;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;

}

#hirestartup h1 {
  color: black;
  font-size: 4.0rem;
}

#hirestartup {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#hirestartup:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hirestartup.container {
  position: relative;
}

.compier {
  background-color: #f2f2f2;
}

.glasser {
  background-color: #f2f2f2;
}

/* ................ hire back end start.................. */
#hirebackend {
  background-image: url(./images/hire_devs/backend.jpg);
  width: 100%;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;

}

#hirebackend h1 {
  color: black;
  font-size: 4.0rem;
}

#hirebackend {

  width: 100%;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;

}

#hiresenior {
  background-image: url(./images/hire_devs/senior.jpg);
  width: 100%;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;

}

#hireseniorandroid {
  background-image: url(./images/hire_devs/senior-android.png);
  width: 100%;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;

}

#hirelead {
  background-image: url(./images/hire_devs/lead.jpg);
  width: 100%;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;

}

#hirestartup {
  background-image: url(./images/hire_devs/startup.png);
  width: 100%;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;

}

/*  hire back end */
#hirebackend {
  background-image: url(./images/hire_devs/backend.jpg);
  width: 100%;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;

}

#hirebackend h1 {
  color: black;
  font-size: 4.0rem;
}

#hirebackend {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#hirebackend:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hirebackend .container {
  position: relative;
}

/* ................ hire back end  End................... */

/* .......................hire-fullstack start......................... */
#hirefullstack {
  background-image: url(./images/hire_devs/Full-stack-developer.png);
  width: 100%;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;

}

#hirefullstack h1 {
  color: black;
  font-size: 4.0rem;
}

#hirefullstack {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#hirefullstack:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hirefullstack.container {
  position: relative;
}

/* .......................hire-fullstack end......................... */


/*........................... hire-saftware start...................... */
#hiresoftware {
  background-image: url(./images/hire_devs/software.jpg);
  width: 100%;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;
}

#hiresoftware h1 {
  color: black;
  font-size: 4.0rem;
}

#hiresoftware {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#hiresoftware:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hiresoftware.container {
  position: relative;
}

#aaran {
  background-color: #f2f2f2;
}

#raaser {
  background-color: #f2f2f2;
}

/*........................... hire-saftware end...................... */


/* ...................hire blockchain  start....................... */
#hireeblock {
  background-image: url(./images/hire_devs/software.jpg);
  width: 100%;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;

}

#hireeblock h1 {
  color: black;
  font-size: 4.0rem;
}

#hireeblock {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#hireeblock:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

/* ...................hire blockchain  end....................... */


/*..............................hire_javascript start.................... */
#hireejavacript {
  background-image: url(./images/hire_devs/javascript.png);
  width: 100%;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;
}

#hireejavacript h1 {
  color: black;
  font-size: 4.0rem;
}

#hireejavacript {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#hireejavacript:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hireejavacript.container {
  position: relative;
}

/* .................hire_spring start.................... */
#hireeoffspring {
  background-image: url(./images/hire_devs/spring.avif);
  width: 100%;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;
}

#hireeoffspring h1 {
  color: white;
  font-size: 4.0rem;
}

#hireeoffspring {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#hireeoffspring:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hireeoffspring.container {
  position: relative;
}

/* .................hire_spring end.................... */

/* .........................hire_swift start........................... */
#hireeoffswift {
  background-image: url(./images/hire_devs/swift.avif);
  width: 100%;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;
}

#hireeoffswift h1 {
  color: white;
  font-size: 4.0rem;
}

#hireeoffswift {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#hireeoffswift:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hireeoffswift.container {
  position: relative;
}

.rower {
  background-color: #f2f2f2;
}

/* .................................hire offshore end............................ */


/* ...................................hire_iphone start.................................. */
#hireeiphone {
  background-image: url(./images/hire_devs/iphone.avif);
  width: 100%;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;
}

#hireeiphone h1 {
  color: black;
  font-size: 4.0rem;
}

#hireeiphone {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#hireeiphone:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hireeiphone.container {
  position: relative;
}

.true {
  background-color: #f2f2f2;
}

/* ............................hire-iphone end.......................... */


/* ........................hire-kotlin start.................................... */
#hirekotlin {
  background-image: url(./images/hire_devs/kotlin.png);
  width: 100%;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;
}

#hirekotlin {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#hirekotlin:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hirekotlin.container {
  position: relative;
}

/* .............................hire kotlin end........................... */



/* hire_machine_learning */
#hireemachine {
  background-image: url(./images/hire_devs/hire_machine.jpg);
  width: 100%;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;
}

/* ...........hire-datascience start.........  */
#hiredatascience {
  background-image: url(./images/hire_devs/datascience.avif);
  width: 100%;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;
}

#hiredatascience h1 {
  color: white;
  font-size: 4.0rem;
}

#hiredatascience {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#hiredatascience:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hiredatascience.container {
  position: relative;
}

/* ...........hire-datascience end.........  */
#hireemachine h1 {
  color: white;
  font-size: 4.0rem;
}

#hireemachine {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#hireemachine:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hireemachine.container {
  position: relative;
}

/* ................hire_typescript start............ */
#hireetypescript {
  background-image: url(./images/hire_devs/hire_typescript.jpg);
  width: 100%;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;
}

#hirearchitect {
  background-image: url(./images/hire_devs/software-architecture.jpeg);
}

#hireetypescript h1 {
  color: white;
  font-size: 4.0rem;
}

#hireetypescript {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#hireetypescript:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hireetypescript.container {
  position: relative;
}

/* ................hire_typescript  end............ */

/* ................hire_architect  start............ */
#hirearchitect {
  background-image: url(./images/hire_devs/software-architecture.jpeg);
  width: 100%;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;
}

#hirearchitect h1 {
  color: white;
  font-size: 4.0rem;
}

#hirearchitect {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#hirearchitect:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hirearchitect.container {
  position: relative;
}

/* ................hire_architect  ............ */

/* ...............hire_react start.............. */
#hirereact {
  background-image: url(./images/hire_devs/hire_react.jpg);
  width: 100%;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;
}

#hirereact h1 {
  color: white;
  font-size: 4.0rem;
}

#hirereact {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#hirereact:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hirereact.container {
  position: relative;
}

.soruce {
  background-color: #f2f2f2;
}

#basha {
  background-color: #f2f2f2;
}

/* ...............hire_react end.............. */



/* ...................hire_remote start............... */
#hireeremote {
  background-image: url(./images/hire_devs/aws_inside.webp);
  width: 100%;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;
}

#hireeremote h1 {
  color: white;
  font-size: 4.0rem;
}

/* h */

#hireeremotet:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hireeremote.container {
  position: relative;
}

/* ...................hire_remote end............... */


/* .....................hire_software_dev start........................ */
#hiresoftwared {
  background-image: url(./images/hire_devs/hire_softwaredev_new.jpg);
  width: 100%;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;

  #hiresoftwared h1 {
    color: white;
    font-size: 4.0rem;
  }

  #hiresoftwared {

    width: 100%;
    height: 75vh;

    background-size: cover;
    opacity: 5;
    position: relative;
  }

  #hiresoftwared:before {
    content: "";
    background: rgb(193 193 193 / 60%);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }

  #hiresoftwared.container {
    position: relative;
  }


}

/* .....................hire_software_dev end........................ */

/* .....................hire_node start........................ */
#hirenode {
  background-image: url(./images/hire_devs/node.jpg);
  width: 100%;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;


}

#hirenode h1 {
  color: white;
  font-size: 4.0rem;
}

/* .....................hire_node end........................ */

/* .....................hire_offshore start........................ */
#hireeoffshore {
  background-image: url(./images/hire_devs/offshore.jpg);
  width: 100%;
  height: 75vh;
  background-size: cover;
  color: white;
  background-attachment: fixed;
}


#hiresoftwared h1 {
  color: white;
  font-size: 4.0rem;
}

#hiresoftwared {

  width: 100%;
  height: 75vh;

  background-size: cover;
  opacity: 5;
  position: relative;
}

#hiresoftwared:before {
  content: "";
  background: rgb(193 193 193 / 60%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hiresoftwared.container {
  position: relative;
}



/* privacy-css code

/* contact-css code  */

/* review-css code */

.review_tabs {
  width: 20% !important;

}

/*.......review mobile responsive......*/
@media (max-width:320px) {
  .review_tabs {
    display: none !important;
  }

  .tabresponsive {
    display: none !important;
  }

  .box {
    display: none !important;
  }

  .mobiletab {
    visibility: visible;
  }

}

/*.......review mobile responsive......*/


/*.......review tablet responsive......*/
@media (max-width:767px) {

  .review_tabs {
    display: none !important;
  }

  .tabresponsive {
    display: none !important;
  }

  .box {
    display: none !important;
  }

  .mobiletab {
    visibility: visible;
  }
}

/*.......reviewtablet responsive......*/

@media (min-width:767px) {

  .mobiletab .faq {
    display: none;
  }

  .visble {
    background-color: #007bff;
  }
}



ul.nav-tabs {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 25%;
  background-color: #294b86;
  position: absolute;
  height: 100%;
  overflow: auto;
  display: inline;
}

.mobile {
  visibility: visible;
}

#footer {
  margin-top: 0px !important;
}


@media screen and (min-width:500px) {
  .mobile {
    display: none;
  }
}

.mobile p {
  font-size: 12px;
}


.card-header i {
  color: #6c757d;
}




/* career-css code */



.css-1n3h4wo {
  height: 420px !important;
}

.vertical-tabs-container {
  display: flex;
  align-items: flex-start;

}

.vertical-tabs {
  display: flex;
  flex-direction: column;
  gap: 10px;

}

.vertical-tab {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  color: #333;
  transition: background-color 0.3s ease;
}

.vertical-tab.active {
  background-color: #007bff;
  color: #fff;
}

.vertical-tab-content {
  padding: 10px;
  border: 1px solid #ccc;
  flex-grow: 1;
  background-color: #fff;
}

.cta {
  position: relative;
  margin: auto;
  padding: 12px 18px;
  transition: all 0.2s ease;
  border: none;
  background: none;
}

.cta:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 50px;
  background: #b1dae7;
  width: 45px;
  height: 45px;
  transition: all 0.3s ease;
}

.cta span {
  position: relative;
  font-family: "Ubuntu", sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: #234567;
}

.cta svg {
  position: relative;
  top: 0;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #234567;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}

.cta:hover:before {
  width: 100%;
  background: #b1dae7;
}

.cta:hover svg {
  transform: translateX(0);
}

.cta:active {
  transform: scale(0.95);
}

.topinfor {
  border: grey dotted 1px;
  background-color: transparent;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  /* background-color: #81a7cd; */

  /* border: 5px solid #294b86 ; */
}

.pass {
  border-radius: 3px;
  padding: 3px;

}

.css-1aquho2-MuiTabs-indicator {
  background-color: white !important;
}



/* application pages by john */
.containeraddproject {
  border-radius: 10px;
  background-color: grey;
}


.container .fieldapplicaion input {
  padding: 15px 20px;
  width: 100%;
  font-size: 1em;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  background-color: #dedbd2;
}

.container .fieldapplicaion input:focus {
  border: 2px solid #294b86;
  outline: 0;
}

.container .fieldapplicaion2 input {
  padding: 15px 20px;

  font-size: 1em;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  background-color: #dedbd2;
}

.container .fieldapplicaion2 input:focus {
  border: 2px solid #294b86;
  outline: 0;
}



.buttonforproject {
  margin-top: 25px;
  margin-bottom: 20px;
}

.buttonforproject a {
  padding: 15px 20px;
  background: #42b72a;
  border-radius: 6px;
  color: #fff;
  font-size: 1.063rem;
  font-weight: 600;
  transition: 0.2s ease;
  text-decoration: none;
}



.calender {
  position: absolute;
  left: 620px;
  top: 509px;
}


.calender2 {
  position: absolute;
  right: 196px;
  top: 509px;
}

.calender3 {
  position: absolute;
  right: 196px;
  top: 515px;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #294b86 !important;
}

.MuiButton-containedPrimary {
  color: #fff !important;
  background-color: #294b86 !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: #294b86 !important;
}

#signUpForm .form-footer .buttonfordev {
  background-color: #4285f4;
  border: 1px solid #4285f4 !important;
  color: #ffffff;
  border: none;
  padding: 13px 30px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 2px;
  flex: 1;
}

#signUpForm .form-footer .buttonfordev a {
  color: black;
  font-size: 1em;
  font-family: "SF Pro Display"
}

#signUpForm .form-footer button:hover {
  opacity: 0.8;
}

.lab {
  display: inline-block;
  padding: 10px 15px;
  background-color: #294b86;
  /* Change to your desired background color */
  color: white;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 5px;
}
.lab:active {
  background-color: #294b86;
  transform: translateY(4px);
}

/* Hide the file input */
input[type="file"] {
  display: none;
}

#password {
  border: none !important;
  padding-left: 3px !important;
}

.whole_password {
  border: 1px solid #e3e3e3;
  height: 57px;
  width: 99%;
  border-radius: 5px;
}

.form {
  display: flex;
  flex-direction: column;
  background: #606c88;
  background: -webkit-linear-gradient(to right, #3f4c6b, #606c88);
  background: linear-gradient(to right, #3f4c6b, #606c88);
  padding: 20px;
  border-radius: 10px;
  max-width: 350px;
}

.title {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 700;
  letter-spacing: -0.025em;
  color: #fff;
}

.description {
  line-height: 1.5rem;
  font-size: 1rem;
  margin-top: 1rem;
  color: rgb(209 213 219);
}

.form div {
  display: flex;
  max-width: 28rem;
  margin-top: 1rem;
  column-gap: 0.5rem;
}

.form div input {
  outline: none;
  line-height: 1.5rem;
  font-size: 0.875rem;
  color: rgb(255 255 255);
  padding: 0.5rem 0.875rem;
  background-color: rgb(255 255 255 / 0.05);
  border: 1px solid rgba(253, 253, 253, 0.363);
  border-radius: 0.375rem;
  flex: 1 1 auto;
}

.form div input::placeholder {
  color: rgb(216, 212, 212);
}

.form div input:focus {
  border: 1px solid rgb(99 102 241);
}

.form div {
  color: #fff;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.625rem 0.875rem;
  background-color: rgb(99 102 241);
  border-radius: 0.375rem;
  border: none;
  outline: none;
}





@media (min-width: 1440px) {}

@media (min-width: 768px) {
  .buttoncomp {
    width: 60%;
    margin: auto;


  }


}

.buttoncomp p {
  font-size: 18px;
  margin-top: 2px;
}


.form2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  width: 300px;
  background-color: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0px 20px 25px rgb(0 0 0 / 9%) !important;
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: black
}

.message {
  color: #a3a3a3;
  font-size: 14px;
  margin-top: 4px;
  text-align: center
}

.inputs {
  margin-top: 10px
}

.inputs input {
  width: 32px;
  height: 32px;
  text-align: center;
  border: none;
  border-bottom: 1.5px solid #d2d2d2;
  margin: 0 10px;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  max-width: 279px !important;
}

.inputs input:focus {
  border-bottom: 1.5px solid royalblue;
  outline: none;
}

.action2 {
  margin-top: 24px;
  padding: 12px 16px;
  border-radius: 8px;
  border: none;
  background-color: royalblue;
  color: white;
  cursor: pointer;

}

.form-container {
  max-width: 400px;
  background-color: #fff;
  padding: 32px 24px;
  font-size: 14px;
  font-family: inherit;
  color: #212121;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
}

.form-container button:active {
  scale: 0.95;
}

.form-container .logo-container {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
}

.form-container .form3 {
  display: flex;
  flex-direction: column;
}

.form-container .form-group {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.form-container .form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-container .form-group input {
  width: 100%;
  padding: 12px 16px;
  border-radius: 6px;
  font-family: inherit;
  border: 1px solid #ccc;
}

.form-container .form-group input::placeholder {
  opacity: 0.5;
}

.form-container .form-group input:focus {
  outline: none;
  border-color: #1778f2;
}

.form-container .form-submit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  color: #fff;
  background-color: #294b86;
  border: none;
  width: 100%;
  padding: 12px 16px;
  font-size: inherit;
  gap: 8px;
  margin: 12px 0;
  cursor: pointer;
  border-radius: 6px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
}

.form-container .form-submit-btn:hover {
  background-color: #313131;
}

.form-container .link {
  color: #1778f2;
  text-decoration: none;
}

.form-container .signup-link {
  align-self: center;
  font-weight: 500;
}

.form-container .signup-link .link {
  font-weight: 400;
}

.form-container .link:hover {
  text-decoration: underline;
}

.form4 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 300px;
  background-color: #fff;
  border-radius: 20px;
  padding: 30px 20px;
  box-shadow: 0px 20px 25px rgb(0 0 0 / 9%) !important;
  margin-top: 150px !important;
}

.title2 {
  color: black;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  margin-bottom: 4px;
}

.sub {
  text-align: center;
  color: black;
  font-size: 14px;
  width: 100%;
}

.sub.mb {
  margin-bottom: 1px;
}

.sub a {
  color: rgb(23, 111, 211);
}

.avatar {
  height: 50px;
  width: 50px;
  background-color: rgb(23, 111, 211);
  border-radius: 50%;
  align-self: center;
  padding: 6px;
  cursor: pointer;
  box-shadow: 12.5px 12.5px 10px rgba(0, 0, 0, 0.015), 100px 100px 80px rgba(0, 0, 0, 0.03);
}

.form {
  align-self: flex-end;
}

.input2 {
  border: none;
  outline: none;
  width: 100%;
  padding: 16px 10px;
  background-color: rgb(203 255 252);
  border-radius: 10px;
  box-shadow: 12.5px 12.5px 10px rgba(0, 0, 0, 0.015), 100px 100px 80px rgba(0, 0, 0, 0.03);
}

.resetbutton {
  margin-top: 12px;
  background-color: rgb(23, 111, 211);
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  background-color: #0066ff;
  color: #fff;
  border: 0;
  border-radius: 24px;
  padding: 10px 16px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color .3s ease;
}

.resetbutton:hover {
  background-color: #005ce6;
}

.input:focus {
  border: 1px solid rgb(23, 111, 211);
}

#file {
  display: none;
}

.otpstyle {
  box-shadow: 0px 20px 25px rgb(0 0 0 / 9%) !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: #0d58ba !important;
}

.wholevalidateemail {
  background-color: #f4f7f9;
}

.google {
  border-radius: 10px !important;
  box-shadow: none !important;
  background-color: #0066ff !important;
  color: white !important;
}

.devAndHire a {
  font-weight: 200;
}

.cancel {
  margin: 10px !important;
  color: red !important;

}

.cancel button {
  color: #c82b2b !important;
  font-size: 18px;

}

.in input {
  width: 300px !important;
}


/*...homepage changes....*/
button:hover {
  cursor: pointer;
}

.fPUkBr {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;

}


.ljljuX {
  box-sizing: border-box;
  padding: 0;
  transition: all 250ms ease;
  border: none;
  margin: 5px;
  background-color: rgb(1 141 255 / 50%) !important;

  font-size: 1.3em;
  content: "";
  height: 10px;
  width: 10px;
  box-shadow: 0 0 1px 3px #294b86 !important;
  border-radius: 50%;
  outline: none;
}

.homeimg {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
}


/*...............santhosh .....................*/
/*...homepage changes....*/


/*...aboutpage changes....*/
.owncolor {
  background-color: #effafc;
}

/*...aboutpage changes....*/

/*...Contactpage changes....*/
.gmap_canvas {
  height: 8rem;
}


@media screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .laptopmap {
    display: none !important;
  }

  .mobilemap {
    visibility: visible;

  }

  .mobilemap .gmap_iframe {
    position: relative;
  }

  .mobilemap .frames {
    justify-content: center;
  }

  .tabmap {

    display: none !important;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .laptopmap {
    visibility: hidden;
    display: none !important;
  }

  .mobilemap {
    display: none;
  }

  .tabmap {
    visibility: visible;
  }
}

@media screen and (min-device-width: 1025px) {
  .laptopmap {
    visibility: visible;
  }

  .mobilemap {
    display: none;
  }

  .tabmap {
    display: none;
  }
}

/*...Contactpage changes....*/
.greybg {
  background-color: #f6f9fe;
}

.MuiBox-root .MuiBox-root-6 {
  padding: 0 !important;
}



/* john */
@media screen and (max-width: 360px) {
  #signUpForm {
    max-width: 750px;
    background-color: #ffffff;
    margin: 60px auto;
    padding: 0px;
    box-shadow: 0px 20px 25px rgb(0 0 0 / 9%);
    border-radius: 12px;
    position: relative;
  }

  #signUpForm h2 {
    font-size: 23px;
  }

  #signUpForm p {
    font-size: 15px;
    text-align: justify;
    padding-left: 5px;
    padding-right: 5px;
  }

  .form-control {
    padding: 13px 11px !important;

  }

  .form-control-lg {
    border-radius: 5px !important;
  }

}

.form-control {
  padding: 13px 11px !important;
}

.form-control-lg {
  border-radius: 5px !important;
}

@media screen and (min-device-width: 1025px) {
  .laptopmap {
    visibility: visible;
  }

  .mobilemap {
    display: none;
  }

  .tabmap {
    display: none;
  }
}

/*...Contactpage changes....*/
.greybg {
  background-color: #f6f9fe;

}

.brownbg {
  background-color: #f6f9fe;

}


@media screen and (max-device-width: 360px) {
  .mediap p {
    padding-left: 12px;

  }
}

#mediabutton {
  text-align: center;

}


/*...mediaquiery for button.....*/
@media screen and (max-device-width: 450px) {

  /*...mediaquiery for button.....*/
  .Toastify__toast-theme--light {
    background-color: red !important;
    color: white !important;
  }

  @media screen and (max-device-width: 450px) {
    #mediabutton {
      display: flex;
      justify-content: center;
    }
  }

  /*...mediaquiery for button.....*/
  @media screen and (max-device-width: 450px) {

    #databasedev h1 {
      color: white;
    }
  }

  @media screen and (max-device-width: 450px) {

    #softwares h1 {
      color: white;
    }
  }

  #mediabutton button {
    text-transform: uppercase;
  }

  #embedded h1 {
    color: white;
  }
}

#seniordev {
  /* / NOT ADD IMG / */
  background-image: url(./images/home/cplus.jpg);
  height: 75vh;
  background-size: cover;

  background-attachment: fixed;
  opacity: 1.0
}

@media screen and (max-device-width: 450px) {

  #softwares h1 {
    color: white;
  }
}

#mediabutton button {
  text-transform: uppercase;
}

/* ---------------------------john------------------------------------ */

/* ----------------------------------------------------------------------dashboard */



.sidebar {
  position: fixed;
  height: 100%;
  width: 235px;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  background: #294b86;
  transition: left 300ms;
}

.side-header img {
  height: 62px;
  width: 177px;
}

.side-header {
  box-shadow: 0px 5px 5px -5px rgb(0 0 0 /10%);
  background: #ffffff;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#menu-toggle {
  display: none;

}

.side-header h3,
side-head span {
  color: #fff;
  font-weight: 400;
}

.side-content {
  height: calc(100vh - 60px);
  overflow: auto;
  padding: 10px !important;
}

/* width */
.side-content::-webkit-scrollbar {
  width: 5px;
}


.side-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.side-content::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border-radius: 10px;
}

/* Handle on hover */
.side-content::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

.profile {
  text-align: center;
  padding: 2rem 0rem;
}

.bg-img {
  background-image: url(./images/hire_devs/hire_machine.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  background-size: cover;
}

.profile-img {
  height: 80px;
  width: 80px;
  display: inline-block;
  margin: 0 auto .5rem auto;
  border: 3px solid #899DC1;
}

.profile h4 {
  color: #fff;
  font-weight: 500;
}

.profile small {
  color: #899DC1;
  font-weight: 600;
}

.side-menu ul {
  padding-left: 0% !important;
}

.side-menu ul li a {
  padding: 12px !important;
}

.side-menu {
  margin-top: 50px;
}

.side-menu a {
  cursor: pointer;
  display: block;
  padding: 1.0rem 0rem;
  text-align: center;
}

.side-menu a.active {
  background: #fff;
}

.side-menu a.active span,
.side-menu a.active small {
  color: black;
}

.side-menu a span {
  display: block;
  text-align: center;
  font-size: 1.7rem;
}

.side-menu a span,
.side-menu a small {
  color: #fff;
  font-size: 20px;
}

#menu-toggle:checked~.sidebar {
  width: 60px;
}

#menu-toggle:checked~.sidebar .side-header span {
  display: none;
}

#menu-toggle:checked~.main-content {
  margin-left: 60px;
  width: calc(100% - 60px);
}

#menu-toggle:checked~.main-content header {
  left: 60px;
}


#menu-toggle:checked~.sidebar .profile,
#menu-toggle:checked~.sidebar .side-menu a small {
  display: none;
}

#menu-toggle:checked~.sidebar .side-menu a span {
  font-size: 1.3rem;
}


.main-content {
  margin-left: 215px;
  /* width: calc(100% - 165px); */
  transition: margin-left 300ms;
}

.dashboard-header {
  position: fixed;
  right: 0;
  top: 0;
  left: 165px;
  z-index: 1;
  height: 79px;
  box-shadow: 0px 5px 5px -5px rgb(0 0 0 /10%);
  background: #fff;
  transition: left 300ms;
}

.header-content,
.header-menu {
  display: flex;
  align-items: center;
}

.header-content {
  justify-content: space-between;
  padding: 0rem 1rem;
}

.header-content label:first-child span {
  font-size: 1.3rem;
}

.header-content label {
  cursor: pointer;
}

.header-menu {
  justify-content: flex-end;
  padding-top: .5rem;
}

.header-menu .notify-icon {
  margin-right: 2rem;
  position: relative;
}

.header-menu label span,
.notify-icon span:first-child {
  font-size: 1.3rem;
}

.notify-icon span:last-child {
  position: absolute;
  background: var(--main-color);
  height: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  right: -5px;
  top: -5px;
  color: #fff;
  font-size: .8rem;
  font-weight: 500;
}


.user {
  display: flex;
  align-items: center;
}

.user div,
.client-img {
  height: 40px;
  width: 40px;
  margin-right: 1rem;
}

.user span:last-child {
  display: inline-block;
  margin-left: .3rem;
  font-size: .8rem;
}

.dashboard-main {
  margin-top: 60px;
  margin-left: 20px;
}

.page-header {
  padding: 1.3rem 1rem;
  background: #E9edf2;
  border-bottom: 1px solid #dee2e8;
}

.page-header h1,
.page-header small {
  color: #74767d;
}

.page-content {
  padding: 1.3rem 1rem;
  background: #f1f4f9;
}

.analytics {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  margin-top: .5rem;
  margin-bottom: 2rem;
}

.dashboard-card {
  box-shadow: 0px 5px 5px -5px rgb(0 0 0 / 10%);
  background: #fff;
  padding: 1rem;
  border-radius: 3px;
}


.card-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-head h2 {
  color: #333;
  font-size: 1.8rem;
  font-weight: 500;
}

.card-head span {
  font-size: 10px;
  color: #B0B0B0;
}

.card-progress small {
  color: #777;
  font-size: .8rem;
  font-weight: 600;
}


.card-indicator {
  margin: .7rem 0rem;
  height: 10px;
  border-radius: 4px;
  background: #e9edf2;
  overflow: hidden;
}

.indicator {
  height: 10px;
  border-radius: 4px;
}

.indicator.one {
  background: #22baa0;
}

.indicator.two {
  background: #11a8c3;
  width: 80%
}

.indicator.three {
  background: #f6d433;
  width: 65%
}

.indicator.four {
  background: #f25656;
}

.records {
  box-shadow: 0px 5px 5px -5px rgb(0 0 0 / 10%);
  background: #fff;
  border-radius: 3px;
}


.record-header {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashbaord-add,
.browse {
  display: flex;
  align-items: center;
}

.dashbaord-add span {
  display: inline-block;
  margin-right: .6rem;
  font-size: .9rem;
  color: #666;
}



.dash-button {
  outline: none;
}

.dash-input {
  outline: none;
}


.table-responsive {
  width: 100%;
  overflow: auto;
}

table {
  border-collapse: collapse;
}

table thead tr {
  background: #e9edf2;
}

table thead th {
  padding: 1rem 0rem;
  text-align: left;
  color: #444;
  font-size: .9rem;
}

table thead th:first-child {
  padding-left: 1rem;
}

table tbody td {
  padding: 1rem 0rem;
  color: #444;
}

table tbody td:first-child {
  padding-left: 1rem;
  color: var(--main-color);
  font-weight: 600;
  font-size: .9rem;
}

table tbody tr {
  border-bottom: 1px solid #dee2e8;
}


.dashbaord-client {
  display: flex;
  align-items: center;
}

.client-img {
  margin-right: .5rem;
  border: 2px solid #b0b0b0;
  height: 45px;
  width: 45px;
}

.client-info h4 {
  color: #555;
  font-size: .95rem;
}

.client-info small {
  color: #777;
}


.actions span {
  display: inline-block;
  font-size: 1.5rem;
  /* padding: 0 5px; */
  margin-right: .5rem;
}

.paid {
  display: inline-block;
  text-align: center;
  font-weight: 600;
  color: var(--main-color);
  background: #e5f8ed;
  padding: .5rem 1rem;
  border-radius: 20px;
  font-size: .8rem;
}

.side-menu ul li {
  list-style: none;
}

.side-menu ul li a {
  text-decoration: none;
  border-radius: 25px;
}



@media only screen and (max-width: 1200px) {
  .analytics {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 768px) {
  .analytics {
    grid-template-columns: 100%;
  }

  .sidebar {
    left: -234px;
    z-index: 90;
  }

  .dashboard-header {
    left: 0;
    width: 100%;
  }

  .main-content {
    margin-left: 0;
    width: 100%;
  }

  #menu-toggle:checked~.sidebar {
    left: 0;
  }

  #menu-toggle:checked~.sidebar {
    width: 240px;
  }

  #menu-toggle:checked~.sidebar .side-header span {
    display: inline-block;
  }

  #menu-toggle:checked~.sidebar .profile,
  #menu-toggle:checked~.sidebar .side-menu a small {
    display: block;
  }

  #menu-toggle:checked~.sidebar .side-menu a span {
    font-size: 1.7rem;
  }

  #menu-toggle:checked~.main-content header {
    left: 0px;
  }
.videoContent h5{
  font-size: 12px !important;
  line-height: 20px !important;

}
  table {
    width: 900px;
  }
}

.dashboard-form {
  border-radius: 2px;
  box-shadow: 0 0 0 1px #e2e5e8;
  border: none;
  margin-bottom: 30px;
  transition: all 0.5s ease-in-out;
}

.card-body {
  padding: 21px 24px;
  /* width: 700px!important; */
  /* height: 100vh; */
}

#profilecard {
  border-radius: 2px;
  box-shadow: 0 0 0 1px #e2e5e8;
  border: none;
  margin-bottom: 30px;
  transition: all 0.5s ease-in-out;
  height: 80%
}

.active>.page-link {
  color: white;
  background-color: #052c65 !important;
  ;

}

.page-link {
  /* color: #052c65!important; */
  margin-top: 20px;
}

.page-item {
  color: white;


}


#profilecard .card-header {
  background-color: transparent;
  border-bottom: 1px solid #e2e5e8;
  padding: 20px 25px;
  position: relative;
}

.wid-100 {
  width: 100px;
}

.img-radius {
  border-radius: 50%;
}

.bg-c-blue {
  background: linear-gradient(45deg, #3949AB, #2962FF) !important;
}

/* Normal state */
.normal-border {
  border: 1px solid #ccc;
  /* Default border color */
}

/* Error state */
.error-border {
  border: 1px solid n;
  /* Error border color */
}

.popup {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 40vh !important;
  width: 100% !important;

}

.model col {
  text-align: center !important;
  justify-content: space-around;
}

.actions {
  cursor: pointer;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0
}


.noshow {
  display: none !important;
}

errors {
  color: red;

}

.card-project {
  background-color: #ffffff;
  padding: 25px;
  width: 90%;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.card-project-head {
  background-color: grey;
  height: 10vh;
  width: auto;
}

/* 
.leftside-profile{
  border-radius: 10px 0px 0px 10px;
  background-color: red;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.profile-col{
  border-radius: 0px 10px 10px 0px;
  padding: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}


.rightside-profile{
}

.profile-dev{
  margin-top: 50px !important;
}
.right-column{
} */


.profile-admin {
  padding: 10px;
  background-color: #28223e;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 10px;
  padding: 10px;
  width: 40%;
  margin-top: 30px;

}

.profile-admin h4 {
  color: #ffffff;
}

.profile-admin h5 {
  color: #ffffff;
}

.profile-admin h6 {
  color: #ffffff;
}

.profile-admin p {
  color: #ffffff;
}

.skills-fill p {
  border: 1px solid;
  border-style: dotted;
}

.skills-fill {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.profile-admin-img img {
  width: 150px;
  height: 140px;
  border-radius: 50%;
  background-color: #294b86;
  border-style: solid;
  border-color: #ffffff;

}

.whole-screen {
  background-image: url(../src/images/home/profile_background.jpeg);
  object-fit: cover;
  background-size: cover;
  color: white;
  background-attachment: fixed;
  opacity: 0.9;
  height: 100vh;
}

@media screen and (max-width: 360px) {

  .profile-admin-img img {
    width: 132px;
    height: 128px;
  }

  .profile-admin {
    width: 99%;
  }
}

.uibtn {
  outline: 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: #293a7f !important;
  min-width: 200px;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
  box-sizing: border-box;
  padding: 6px 20px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  overflow: hidden;
  cursor: pointer;
}

.uibtn:hover {
  opacity: .95;
}

.uibtn .animation {
  border-radius: 100%;
  animation: ripple 0.6s linear infinite;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1), 0 0 0 80px rgba(255, 255, 255, 0);
  }
}

.custom-google-login-button {
  background-color: #4286f5 !important;
  padding: 3px !important;
  color: #ffffff !important;
  font-size: 16px !important;
  width: 100%;
  text-align: center !important;
}

.custom-google-login-button:hover {
  background-color: #3b6de1;
}

.whole_div {
  margin-left: 5px !important;
}

.sampleButton {
  border: none;
  background-color: #556588;
  color: white;
}

/* LOADING STYLE START */
.spinner {
  position: absolute;
  width: 9px;
  height: 9px;
  margin: 8% 0% 0% 46%;

}

.spinner div {
  position: absolute;
  width: 50%;
  height: 150%;
  background: #294b86;
  transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1%));
  animation: spinner-fzua35 1s calc(var(--delay) * 1s) infinite ease;
}

.spinner div:nth-child(1) {
  --delay: 0.1;
  --rotation: 36;
  --translation: 150;
}

.spinner div:nth-child(2) {
  --delay: 0.2;
  --rotation: 72;
  --translation: 150;
}

.spinner div:nth-child(3) {
  --delay: 0.3;
  --rotation: 108;
  --translation: 150;
}

.spinner div:nth-child(4) {
  --delay: 0.4;
  --rotation: 144;
  --translation: 150;
}

.spinner div:nth-child(5) {
  --delay: 0.5;
  --rotation: 180;
  --translation: 150;
}

.spinner div:nth-child(6) {
  --delay: 0.6;
  --rotation: 216;
  --translation: 150;
}

.spinner div:nth-child(7) {
  --delay: 0.7;
  --rotation: 252;
  --translation: 150;
}

.spinner div:nth-child(8) {
  --delay: 0.8;
  --rotation: 288;
  --translation: 150;
}

.spinner div:nth-child(9) {
  --delay: 0.9;
  --rotation: 324;
  --translation: 150;
}

.spinner div:nth-child(10) {
  --delay: 1;
  --rotation: 360;
  --translation: 150;
}

@keyframes spinner-fzua35 {

  0%,
  10%,
  20%,
  30%,
  50%,
  60%,
  70%,
  80%,
  90%,
  100% {
    transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1%));
  }

  50% {
    transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1.5%));
  }
}

.spinner2 {
  position: absolute;
  width: 9px;
  height: 9px;
  margin: 25% 0% 0% 50%;

}

.spinner2 div {
  position: absolute;
  width: 50%;
  height: 150%;
  background: #294b86;
  transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1%));
  animation: spinner2-fzua35 1s calc(var(--delay) * 1s) infinite ease;
}

.spinner2 div:nth-child(1) {
  --delay: 0.1;
  --rotation: 36;
  --translation: 150;
}

.spinner2 div:nth-child(2) {
  --delay: 0.2;
  --rotation: 72;
  --translation: 150;
}

.spinner2 div:nth-child(3) {
  --delay: 0.3;
  --rotation: 108;
  --translation: 150;
}

.spinner2 div:nth-child(4) {
  --delay: 0.4;
  --rotation: 144;
  --translation: 150;
}

.spinner2 div:nth-child(5) {
  --delay: 0.5;
  --rotation: 180;
  --translation: 150;
}

.spinner2 div:nth-child(6) {
  --delay: 0.6;
  --rotation: 216;
  --translation: 150;
}

.spinner2 div:nth-child(7) {
  --delay: 0.7;
  --rotation: 252;
  --translation: 150;
}

.spinner2 div:nth-child(8) {
  --delay: 0.8;
  --rotation: 288;
  --translation: 150;
}

.spinner2 div:nth-child(9) {
  --delay: 0.9;
  --rotation: 324;
  --translation: 150;
}

.spinner2 div:nth-child(10) {
  --delay: 1;
  --rotation: 360;
  --translation: 150;
}

@keyframes spinner2-fzua35 {

  0%,
  10%,
  20%,
  30%,
  50%,
  60%,
  70%,
  80%,
  90%,
  100% {
    transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1%));
  }

  50% {
    transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1.5%));
  }
}

/* Loading Style End */


.profile-admin-card-client {
  margin-top: 150px;
  color: #1a202c;
  text-align: left;
}

.profile-admin-card-deve {
  margin-top: 20px;
  color: #1a202c;
  text-align: left;
}

.main-body-project-card {
  padding: 15px;
}



.card-project-admin {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06);
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, .125);
  border-radius: .25rem;
}

.card-project-admin .card-body-project {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem;
}

.gutters-sm {
  margin-right: -8px;
  margin-left: -8px;
}

.gutters-sm>.col,
.gutters-sm>[class*=col-] {
  padding-right: 8px;
  padding-left: 8px;
}


#myfile {
  background-color: grey;
  padding: 10px;
}


.cv-button {
  border: none;
  outline: none;
  padding: 12px;
  color: #fff !important;
  background-color: #294b86 !important;
  border-radius: 5px;
}

.pdf-viewer{
  position: absolute;
}

.border-project{
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06);
  border: 1px solid lightgrey;
  padding: 15px;

}

.border-project-p h6{
  color: grey;
  font-size: 12px;
}
.border-project button{
  border: none;
  outline: none;
  padding: 5px 9px 5px 9px;
  background-color: #108a00;
  border-radius: 20px;
  color: white;
}

.card-para{
  color: black !important;
  font-size: 20px !important;
}

.card-invol{
  color: grey;
  font-size: 15px;
}

.skills-para p{
      background-color: lightgrey;
      padding: 5px 15px 5px 15px;
      border-radius: 20px;
}

.custom-text{
  background-color: #b0cea6;
  padding: 10px;

  border-radius: 4px;
  color: #176b17;
}
.custom-width{
  width: 100px !important;
}